export const ChangeFileSize = {
  data() {
    return {};
  },
  methods: {
    changeFileSize(e) {
      const MAX_WIDTH = 200;
      let image_file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image_file);
      reader.onload = (event) => {
        let image_url = event.target.result;
        let image = document.createElement("img");
        image.src = image_url;
        image.onload = (evt) => {
          let canvas = document.createElement("canvas");
          let ratio = MAX_WIDTH / evt.target.width;
          canvas.width = MAX_WIDTH;
          canvas.height = evt.target.height * ratio;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          let new_image_url = context.canvas.toDataURL("image/jpeg", 50);
          this.UploadOrganization(new_image_url);
        };
      };
    },
  },
};
