<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      :max-width="StoreObj.plantTYpe == 'AI' || StoreObj.plantTYpe == 'AO' ? '400px' : '400px'"
      v-model="dialogConfigureDashboardParameter"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Configure Parameter</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogConfigureDashboardParameterEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                :md="StoreObj.plantTYpe == 'AI' || StoreObj.plantTYpe == 'AO' ? '12' : '12'"
                class="pb-0"
              >
                <v-select
                  dense
                  outlined
                  readonly
                  label="Parameter Type"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.tag_type"
                  :items="parameterTypeItems"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n3">
              <v-col cols="12" xs="12" sm="6" md="6" class="pb-0" v-if="configure_parameter.tag_type == 'DI'">
                <v-select
                  dense
                  readonly
                  outlined
                  label="Plant Type"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.plant_name"
                  :items="GetAllUFROTypesList"
                  item-text="plant_name"
                ></v-select>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="6" class="py-0" v-if="configure_parameter.tag_type == 'DI'">
                <v-select
                  dense
                  outlined
                  :readonly="StoreObj.action == 'EDIT'"
                  label="Status Or Feedback"
                  class="field_height field_label_size FontSize mt-3"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.status_or_feedback"
                  :items="statusOrFeedbackItems"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                :md="StoreObj.plantTYpe == 'AI' || StoreObj.plantTYpe == 'AO' ? '12' : '12'"
                :class="configure_parameter.tag_type == 'DI' ? 'py-0' : 'py-0'"
              >
                <v-select
                  dense
                  outlined
                  label="Parameter Name"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.tag_description"
                  item-text="tag_description"
                  :items="
                    configure_parameter.tag_type == 'AI' || configure_parameter.tag_type == 'AO'
                      ? GetAllMasterParametersList.filter((item) => item.tag_type == configure_parameter.tag_type)
                      : configure_parameter.tag_type == 'DI'
                      ? GetAllMasterParametersList.filter(
                          (item) =>
                            item.tag_type == configure_parameter.tag_type &&
                            item.status_or_feedback == configure_parameter.status_or_feedback &&
                            item.plant_type == configure_parameter.plant_name
                        )
                      : []
                  "
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="12"
                :md="StoreObj.plantTYpe == 'AI' || StoreObj.plantTYpe == 'AO' ? '12' : '12'"
                sm="3"
                xs="12"
              >
                <v-text-field
                  dense
                  outlined
                  label="Weightage"
                  class="field_height field_label_size FontSize"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                  ]"
                  v-model="configure_parameter.sort_count"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="1" class="">
                <v-checkbox dense class="ma-0 pb-0" v-model="configure_parameter.show_on_dashboard"></v-checkbox>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="font-weight-bold mt-1 mr-n7"> Show On Dashboard ?</v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="1" class="font-weight-bold mr-n8">
                <v-checkbox dense class="pb-0 ma-0" v-model="configure_parameter.is_alarm"></v-checkbox>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="font-weight-bold pl-8 mt-1"> Is An Alarm ?</v-col> -->
              <!--  <v-col cols="12" xs="12" sm="12" md="1" class="mr-n8">
                <v-checkbox dense class="pa-0 ma-0" v-model="configure_parameter.show_LED"></v-checkbox>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold mr-n16"> Show LED ?</v-col> -->
            </v-row>
            <!-- 
            <v-row class="py-0 mt-n4" v-if="configure_parameter.is_alarm == true">
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  dense
                  outlined
                  label="Start Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.start_value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-text-field
                  dense
                  outlined
                  label="End Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="configure_parameter.end_value"
                ></v-text-field>
              </v-col>
            </v-row> -->
            <!-- <v-card
              outlined
              class="elevation-0 pa-2"
              v-if="
                configure_parameter.status_or_feedback == 'STATUS' ||
                configure_parameter.status_or_feedback == 'FEEDBACK'
              "
            >
              <div class="font-weight-bold">Tag Configuration</div>
              <v-divider class="mx-2" />
              <v-row no-gutters class="pa-2 FontSize font-weight-bold">
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12" xs="12" md="4">color </v-col>
                    <v-col cols="12" xs="12" md="3" class="px-6">value </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      md="5"
                      align="center"
                      v-if="configure_parameter.status_or_feedback == 'FEEDBACK'"
                      >Conditional
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  v-if="
                    configure_parameter.status_or_feedback == 'FEEDBACK' &&
                    create_dashboard_paramater.parameter_values.filter((item) => item.is_conditional == true).length !=
                      0
                  "
                >
                  <v-row>
                    <v-col cols="12" xs="12" md="4">Dependent Tag ID </v-col>
                    <v-col cols="12" xs="12" md="4">Dependent Tag Value </v-col>
                    <v-col cols="12" xs="12" md="4">Dependent Tag Color </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mt-n2" v-for="(item, idx) in create_dashboard_paramater.parameter_values" :key="idx">
                <v-col cols="12" xs="12" sm="12" md="2">
                  <v-select
                    outlined
                    dense
                    class="FontSize field_height field_label_size smallFontSize"
                    :items="Tag_colors"
                    return-object
                    @change="ChangeColor(item)"
                    v-model="item.tag_color"
                  >
                    <template v-slot:selection="data" class="FontSize">
                      <v-icon :color="data.item.toLowerCase()">mdi-circle-slice-8</v-icon>
                      {{ data.item.tag_color }}
                    </template>
                    <template v-slot:item="data" class="pa-3">
                      <v-icon class="ml-6" :color="data.item.toLowerCase()">mdi-circle-slice-8</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1">
                  <v-text-field
                    v-model="item.tag_value"
                    dense
                    outlined
                    class="field_height field_label_size fontSize"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" v-if="configure_parameter.status_or_feedback == 'FEEDBACK'">
                  <v-checkbox
                    dense
                    class="py-0 my-0 pl-9"
                    @change="getItem(item)"
                    v-model="item.is_conditional"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  sm="12"
                  xs="12"
                  align="center"
                  class="mx-12"
                  v-if="configure_parameter.status_or_feedback == 'FEEDBACK'"
                >
                  <v-text-field
                    v-if="item.is_conditional == true"
                    dense
                    outlined
                    v-model="item.dependent_tag_id"
                    class="field_height field_label_size fontSize"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  sm="12"
                  xs="12"
                  align="center"
                  class="mx-0"
                  v-if="configure_parameter.status_or_feedback == 'FEEDBACK'"
                >
                  <v-text-field
                    v-if="item.is_conditional == true"
                    dense
                    outlined
                    v-model="item.dependent_tag_value"
                    class="field_height field_label_size fontSize"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  sm="12"
                  xs="12"
                  align="center"
                  class="mx-10"
                  v-if="configure_parameter.status_or_feedback == 'FEEDBACK'"
                >
                  <v-select
                    v-if="item.is_conditional == true"
                    outlined
                    dense
                    class="FontSize field_height field_label_size smallFontSize"
                    :items="Tag_colors"
                    return-object
                    @change="ChangeColor(item)"
                    v-model="item.dependent_color"
                  >
                    <template v-slot:selection="data" class="FontSize">
                      <v-icon :color="data.item.toLowerCase()">mdi-circle-slice-8</v-icon>
                    </template>
                    <template v-slot:item="data" class="pa-3">
                      <v-icon class="ml-6" :color="data.item.toLowerCase()">mdi-circle-slice-8</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card> -->
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius"
            outlined
            color="primary"
            @click="dialogConfigureDashboardParameterEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { addTagToOrganization, editTagOfOrganizationDashboard } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetAllMasterParameters } from "@/mixins/GetAllMasterParameters.js";
import { GetAllPlantTypes } from "@/mixins/GetAllPlantTypes.js";
export default {
  props: {
    dialogConfigureDashboardParameter: Boolean,
    StoreAllCommonObject: Object,
    current_view: String,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllMasterParameters, GetAllPlantTypes],
  data: () => ({
    loading: false,
    configure_parameter: {
      tag_type: "",
      plant_name: "",
      status_or_feedback: "",
      tag_description: "",
      sort_count: "",
      show_on_dashboard: false,
      is_alarm: false,
      start_value: "",
      end_value: "",
    },
    select_tag_color: "",
    SnackBarComponent: {},
    parameterTypeItems: [
      { text: "Operational", value: "AI" },
      { text: "Summary", value: "DI" },
      { text: "Set Points", value: "AO" },
    ],
    statusOrFeedbackItems: [
      { text: "Status", value: "STATUS" },
      { text: "Feedback", value: "FEEDBACK" },
    ],
    GetAllUFROTypesList: [],
    create_dashboard_paramater: {
      parameter_values: [],
    },
    storeParameterValues: [
      {
        tag_color: "RED",
        tag_value: "",
        is_conditional: false,
        dependent_tag_id: "",
        dependent_tag_value: "",
        dependent_color: "",
      },
      {
        tag_color: "GREEN",
        tag_value: "",
        is_conditional: false,
        dependent_tag_id: "",
        dependent_tag_value: "",
        dependent_color: "",
      },
    ],
    Tag_colors: ["RED", "GREEN", "YELLOW"],
  }),
  watch: {
    select_tag_color(val) {
      console.log("val", val);
      this.Tag_colors.splice(val);
      console.log("1234567890", this.Tag_colors);
    },

    "configure_parameter.status_or_feedback"(val) {
      if (val && val != "") {
        switch (val) {
          case "STATUS":
            this.create_dashboard_paramater.parameter_values = this.storeParameterValues.filter(
              (item) => item.tag_color != "yellow"
            );
            break;
          case "FEEDBACK":
            this.create_dashboard_paramater.parameter_values = this.storeParameterValues;
            break;
        }
      }
    },
    dialogConfigureDashboardParameter(val) {
      if (val == true) {
        console.log("StoreObj", this.StoreObj);
        this.configure_parameter.tag_type =
          this.StoreObj.plantTYpe == "AO" ? "AO" : this.StoreObj.plantTYpe == "AI" ? "AI" : "DI";
        this.configure_parameter.plant_name =
          this.current_view == "CONFIGUREBOARD" ? this.StoreAllCommonObject.plant_name : "";
        if (this.StoreObj.action == "EDIT") {
          this.configure_parameter.tag_type = this.StoreObj.tag_type;
          this.configure_parameter.tag_description = this.StoreObj.tag_description;
          this.configure_parameter.sort_count = this.StoreObj.sort_count;
          this.configure_parameter.status_or_feedback = this.StoreObj.status_or_feedback
            ? this.StoreObj.status_or_feedback
            : "";
          this.configure_parameter.plant_name = this.StoreObj.plant_name ? this.StoreObj.plant_name : "";
          this.configure_parameter.show_on_dashboard = this.StoreObj.show_on_dashboard;
        }

        this.GetAllPlantTypesMethod();
        this.GetAllMasterParametersMethod();
      }
    },
  },
  methods: {
    getItem(item) {
      item.dependent_color = "YELLOW";
      this.create_dashboard_paramater.parameter_values.map((obj, idx) => {
        let Object = obj;
        if (idx != this.create_dashboard_paramater.parameter_values.indexOf(item)) {
          Object.is_conditional = false;
          Object.dependent_tag_id = "";
          Object.dependent_tag_value = "";
          Object.dependent_color = "";
        }
        return Object;
      });
      console.log("Nanthu Chekc", this.create_dashboard_paramater);
    },
    async configureDashboardMethod() {
      var inputParams = {};
      switch (this.StoreObj.action) {
        case "CREATE":
          var mutationname = addTagToOrganization;
          var mutationresult = "AddTagToOrganization";
          inputParams.organization_id = this.StoreAllCommonObject.organization_id;
          inputParams.asset_id = this.StoreAllCommonObject.asset_id;
          inputParams.tag_type = this.configure_parameter.tag_type;
          inputParams.tag_description = this.configure_parameter.tag_description;
          inputParams.tag_id = this.GetAllMasterParametersList.filter(
            (item) => item.tag_description == this.configure_parameter.tag_description
          )[0].tag_id;
          inputParams.plant_name =
            this.configure_parameter.tag_type == "DI" ? this.configure_parameter.plant_name : undefined;
          inputParams.status_or_feedback =
            this.configure_parameter.tag_type == "DI" ? this.configure_parameter.status_or_feedback : undefined;
          inputParams.commisioning_value =
            this.configure_parameter.tag_type == "AI"
              ? this.GetAllMasterParametersList.filter(
                  (tag) => tag.tag_description == this.configure_parameter.tag_description
                )[0].commissioning_value
              : undefined;
          inputParams.tag_values =
            this.configure_parameter.tag_type == "DI"
              ? this.GetAllMasterParametersList.filter(
                  (tag) => tag.tag_description == this.configure_parameter.tag_description
                )[0].parameter_values
              : undefined;
          inputParams.sort_count = this.configure_parameter.sort_count;
          inputParams.show_on_dashboard = this.configure_parameter.show_on_dashboard;
          break;
        case "EDIT":
          mutationname = editTagOfOrganizationDashboard;
          mutationresult = "EditTagOfOrganizationDashboard";
          inputParams.organization_id = this.StoreAllCommonObject.organization_id;
          inputParams.asset_id = this.StoreAllCommonObject.asset_id;
          inputParams.tag_id = this.GetAllMasterParametersList.filter(
            (item) => item.tag_description == this.configure_parameter.tag_description
          )[0].tag_id;
          inputParams.tag_description = this.configure_parameter.tag_description;
          inputParams.sort_count = this.configure_parameter.sort_count;
          inputParams.show_on_dashboard = this.configure_parameter.show_on_dashboard;
          break;
      }

      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogConfigureDashboardParameterEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    validateFormMethod() {
      if (!this.$refs.form.validate()) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };

        // } else if (this.configure_parameter.status_or_feedback == "FEEDBACK") {
        //   if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[0].dependent_color ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[1].dependent_color ||
        //     this.create_dashboard_paramater.parameter_values[0].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[1].dependent_color ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[0].dependent_color ||
        //     this.create_dashboard_paramater.parameter_values[0].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[1].tag_color
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Tag Color and Dependent color should not be same",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value == "" ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value == ""
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value is Mandatory",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value < 0 ||
        //     this.create_dashboard_paramater.parameter_values[0].tag_value > 1 ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value < 0 ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value > 1
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value should be 0 or 1",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value ==
        //     this.create_dashboard_paramater.parameter_values[1].tag_value
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value should not be same",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].is_conditional == false &&
        //     this.create_dashboard_paramater.parameter_values[1].is_conditional == false
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "should be select atleast one Conditional",
        //     };
        //   } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_id == "") {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Dependent tag Id is Mandatory",
        //     };
        //   } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_id.includes("-")) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Invalid Dependent tag Id",
        //     };
        //   } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_value == "") {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Dependent tag value is Mandatory",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].dependent_tag_value < 0 ||
        //     this.create_dashboard_paramater.parameter_values[0].dependent_tag_value > 1
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Dependent tag value should be 0 or 1",
        //     };
        //   } else {
        //     this.configureDashboardMethod();
        //   }
        // } else if (this.configure_parameter.status_or_feedback == "STATUS") {
        //   if (
        //     this.create_dashboard_paramater.parameter_values[1].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[0].dependent_color ||
        //     this.create_dashboard_paramater.parameter_values[0].tag_color ==
        //       this.create_dashboard_paramater.parameter_values[1].tag_color
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Tag Color should not be same",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value == "" ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value == ""
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value is Mandatory",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value < 0 ||
        //     this.create_dashboard_paramater.parameter_values[0].tag_value > 1 ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value < 0 ||
        //     this.create_dashboard_paramater.parameter_values[1].tag_value > 1
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value should be 0 or 1",
        //     };
        //   } else if (
        //     this.create_dashboard_paramater.parameter_values[0].tag_value ==
        //     this.create_dashboard_paramater.parameter_values[1].tag_value
        //   ) {
        //     this.SnackBarComponent = {
        //       SnackbarVmodel: true,
        //       SnackbarColor: "red",
        //       Top: true,
        //       SnackbarText: "Value should not be same",
        //     };
        //   } else {
        //     this.configureDashboardMethod();
        //   }
      } else {
        this.configureDashboardMethod();
      }

      // if (this.$refs.form.validate()) {
      //   this.configureDashboardMethod();
      // } else {
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "red",
      //     Top: true,
      //     SnackbarText: "Invalid Inputs",
      //   };
      // }
    },
    dialogConfigureDashboardParameterEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
