<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogPlantTypeOfOrganization" fullscreen persistent>
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>Configure Plant</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogPlantTypeOfOrganizationEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-5 pb-0" v-if="current_view == 'MAIN_VIEW'">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-form ref="form">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-select
                      dense
                      label="Plant Name"
                      outlined
                      readonly
                      class="field_height field_label_size FontSize"
                      v-model="configure_plant.plant_name"
                      :items="GetAllUFROTypesList"
                      item-text="plant_name"
                      item-value="plant_name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pl-2">
                    <v-text-field
                      dense
                      label="Rated Capacity"
                      outlined
                      class="field_height field_label_size FontSize"
                      v-model="configure_plant.rated_capacity"
                      :rules="[(v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[0-9.]{0,64}$/.test(v) || 'Numeric only']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-select
                      dense
                      label="Hours Of Operations"
                      outlined
                      class="field_height field_label_size FontSize"
                      v-model="configure_plant.rated_hours_of_operation"
                      :items="getHoursItems"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pl-2">
                    <v-select
                      dense
                      label="Time Zone"
                      outlined
                      class="field_height field_label_size FontSize"
                      v-model="configure_plant.time_zone"
                      :items="timeZoneItems"
                      item-text="text"
                      item-value="value"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-toolbar dense class="elevation-0">
                <div class="font-weight-bold ml-n4">Alarms Configuration</div>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="configure_plant.is_alarm_configured == true"
                  small
                  color="primary"
                  @click="(current_view = 'CONFIGURE_ALARM'), (editAlarmConfiguration.action = 'CREATE')"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add Configuration
                </v-btn>
              </v-toolbar>
              <v-checkbox
                dense
                class="px-0 mx-0"
                label="Is Alarm Configured ?"
                v-model="configure_plant.is_alarm_configured"
              ></v-checkbox>
              <v-data-table
                v-if="configure_plant.is_alarm_configured == true"
                dense
                class="elevation-0"
                :headers="alarmConfigurationHeaders"
                :items="alarm_configurations"
              >
                <template v-slot:[`item.configured_alarm_tag`]="{ item }">
                  <div class="FontSize">{{ item.configured_alarm_tag }}</div>
                </template>
                <template v-slot:[`item.alarm_delay`]="{ item }">
                  <div class="FontSize">{{ item.alarm_delay }}</div>
                </template>
                <template v-slot:[`item.alarm_wait_time_config`]="{ item }">
                  <div class="FontSize">{{ item.alarm_wait_time_config }}</div>
                </template>
                <template v-slot:[`item.alarm_wait_time`]="{ item }">
                  <div class="FontSize">
                    {{
                      item.alarm_wait_time_config == "MINUTES"
                        ? item.alarm_wait_time / 60
                        : item.alarm_wait_time_config == "DAYS"
                        ? item.alarm_wait_time / 86400
                        : item.alarm_wait_time_config == "MONTHS"
                        ? item.alarm_wait_time / 2592000
                        : item.alarm_wait_time_config == "HOURS"
                        ? item.alarm_wait_time / 3600
                        : item.alarm_wait_time
                    }}
                  </div>
                </template>
                <template v-slot:[`item.low_range_value`]="{ item }">
                  <div class="FontSize">{{ item.low_range_value }}</div>
                </template>
                <template v-slot:[`item.high_range_value`]="{ item }">
                  <div class="FontSize">{{ item.high_range_value }}</div>
                </template>
                <template v-slot:[`item.range_alarm_message`]="{ item }">
                  <div class="FontSize">{{ item.range_alarm_message }}</div>
                </template>
                <template v-slot:[`item.is_threshold_malfunction_enabled`]="{ item }">
                  <div class="FontSize">{{ item.is_threshold_malfunction_enabled }}</div>
                </template>
                <template v-slot:[`item.low_set_point_greater_than`]="{ item }">
                  <div class="FontSize">
                    {{ isNaN(item.low_set_point_greater_than) ? "-" : item.low_set_point_greater_than }}
                  </div>
                </template>
                <template v-slot:[`item.low_set_point_lesser_than`]="{ item }">
                  <div class="FontSize">
                    {{ isNaN(item.low_set_point_lesser_than) ? "-" : item.low_set_point_lesser_than }}
                  </div>
                </template>
                <template v-slot:[`item.high_set_point_greater_than`]="{ item }">
                  <div class="FontSize">
                    {{ isNaN(item.high_set_point_greater_than) ? "-" : item.high_set_point_greater_than }}
                  </div>
                </template>
                <template v-slot:[`item.high_set_point_lesser_than`]="{ item }">
                  <div class="FontSize">
                    {{ isNaN(item.high_set_point_lesser_than) ? "-" : item.high_set_point_lesser_than }}
                  </div>
                </template>
                <template v-slot:[`item.malfunction_alarm_message`]="{ item }">
                  <div class="FontSize">{{ item.malfunction_alarm_message }}</div>
                </template>
                <template v-slot:[`item.step_number`]="{ item }">
                  <div class="FontSize">{{ item.step_number }}</div>
                </template>
                <template v-slot:[`item.Action`]="{ item }">
                  <v-icon small color="green" @click="CheckItem(item, 'EDIT')" class="mr-2">mdi-pencil</v-icon>
                  <v-icon small color="red" @click="alarm_configurations.splice(alarm_configurations.indexOf(item), 1)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="mt-5 pb-0" v-if="current_view == 'CONFIGURE_ALARM'">
          <v-form ref="alarmform">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-select
                  outlined
                  dense
                  v-if="configure_plant.is_alarm_configured == true"
                  class="field_height field_label_size FontSize"
                  label="Step Number"
                  :items="plantStepItems"
                  item-text="step_name"
                  item-value="step_tag_value"
                  v-model="alarm_configurations_object.step_number"
                ></v-select>
                <v-select
                  dense
                  outlined
                  v-if="configure_plant.is_alarm_configured == true"
                  label="Parameter Name"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="alarm_configurations_object.configured_alarm_tag"
                  item-text="tag_description"
                  item-value="tag_id"
                  :items="GetAllDashboardParameterList"
                ></v-select>
                <v-row>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <v-select
                      outlined
                      dense
                      class="FontSize field_height field_label_size"
                      label="Alarm Wait Time Granulaurity"
                      :items="AlarmWeightIntervals"
                      v-model="alarm_configurations_object.alarm_wait_time_config"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" xs="12">
                    <v-select
                      class="FontSize field_height field_label_size"
                      dense
                      outlined
                      :items="
                        alarm_configurations_object.alarm_wait_time_config == 'MINUTES'
                          ? MinutesInterval
                          : alarm_configurations_object.alarm_wait_time_config == 'HOURS'
                          ? HoursInterval
                          : alarm_configurations_object.alarm_wait_time_config == 'DAYS'
                          ? DaysInterval
                          : alarm_configurations_object.alarm_wait_time_config == 'MONTHS'
                          ? MonthsInterval
                          : ''
                      "
                      item-text="text"
                      item-value="value"
                      v-model="alarm_configurations_object.alarm_wait_time"
                      label="Alarm Wait Time( Debounce For)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-select
                  dense
                  outlined
                  v-if="configure_plant.is_alarm_configured == true"
                  label="Alarm Delay (In Minutes)"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="alarm_configurations_object.alarm_delay"
                  :items="alarmDelayItems"
                ></v-select>
                <v-row v-if="configure_plant.is_alarm_configured == true">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Low Range Value"
                      class="field_height field_label_size FontSize"
                      :rules="[(v) => !!v || 'Required', (v) => /^-?\d*\.{0,1}\d+$/.test(v) || 'Numeric Only']"
                      v-model="alarm_configurations_object.low_range_value"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="High Range Value"
                      class="field_height field_label_size FontSize"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^(?=[a-z A-Z0-9@#._%+-]{0,30}$)[0-9.]{0,64}$/.test(v) || 'Numeric only',
                      ]"
                      v-model="alarm_configurations_object.high_range_value"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  v-if="configure_plant.is_alarm_configured == true"
                  dense
                  outlined
                  label="Range Alarm Message"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="alarm_configurations_object.range_alarm_message"
                ></v-text-field>
                <v-checkbox
                  v-if="configure_plant.is_alarm_configured == true"
                  dense
                  class="pa-0 ma-0"
                  label="Is Threshold Malfunction Check Enabled Configured ?"
                  v-model="alarm_configurations_object.is_threshold_malfunction_enabled"
                ></v-checkbox>

                <div
                  v-if="alarm_configurations_object.is_threshold_malfunction_enabled == true"
                  class="FontSize font-weight-bold"
                >
                  Low Set Point Settings :
                </div>
                <v-row v-if="alarm_configurations_object.is_threshold_malfunction_enabled == true">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size FontSize"
                      label="Greater Than"
                      :disabled="
                        !alarm_configurations_object.low_range_value ||
                        alarm_configurations_object.low_range_value == '' ||
                        !alarm_configurations_object.high_range_value ||
                        alarm_configurations_object.high_range_value == ''
                      "
                      v-model="alarm_configurations_object.low_set_point_greater_than"
                      :rules="[(v) => !!v || 'Required', (v) => /^-?\d*\.{0,1}\d+$/.test(v) || 'Numeric Only']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size FontSize"
                      label="Lesser Than"
                      :disabled="
                        !alarm_configurations_object.low_range_value ||
                        alarm_configurations_object.low_range_value == '' ||
                        !alarm_configurations_object.high_range_value ||
                        alarm_configurations_object.high_range_value == ''
                      "
                      v-model="alarm_configurations_object.low_set_point_lesser_than"
                      :rules="[(v) => !!v || 'Required', (v) => /^-?\d*\.{0,1}\d+$/.test(v) || 'Numeric Only']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div
                  v-if="alarm_configurations_object.is_threshold_malfunction_enabled == true"
                  class="FontSize font-weight-bold"
                >
                  High Set Point Settings :
                </div>
                <v-row v-if="alarm_configurations_object.is_threshold_malfunction_enabled == true">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size FontSize"
                      label="Greater Than"
                      :disabled="
                        !alarm_configurations_object.low_range_value ||
                        alarm_configurations_object.low_range_value == '' ||
                        !alarm_configurations_object.high_range_value ||
                        alarm_configurations_object.high_range_value == ''
                      "
                      :rules="[(v) => !!v || 'Required', (v) => /^-?\d*\.{0,1}\d+$/.test(v) || 'Numeric Only']"
                      v-model="alarm_configurations_object.high_set_point_greater_than"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size FontSize"
                      label="Lesser Than"
                      :disabled="
                        !alarm_configurations_object.low_range_value ||
                        alarm_configurations_object.low_range_value == '' ||
                        !alarm_configurations_object.high_range_value ||
                        alarm_configurations_object.high_range_value == ''
                      "
                      v-model="alarm_configurations_object.high_set_point_lesser_than"
                      :rules="[(v) => !!v || 'Required', (v) => /^-?\d*\.{0,1}\d+$/.test(v) || 'Numeric Only']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  v-if="alarm_configurations_object.is_threshold_malfunction_enabled == true"
                  dense
                  outlined
                  class="field_height field_label_size FontSize"
                  v-model="alarm_configurations_object.malfunction_alarm_message"
                  label="Malfunction Alarm Message"
                ></v-text-field>
                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    v-if="current_view == 'CONFIGURE_ALARM'"
                    class="borderRadius text-capitalize"
                    color="primary"
                    outlined
                    @click="BackToMainPage"
                    ><v-icon small class="mr-1">mdi-chevron-double-left</v-icon>Back</v-btn
                  >
                  <v-btn
                    small
                    v-if="editAlarmConfiguration.action != 'EDIT'"
                    color="primary"
                    @click="pushSettingsToArray()"
                    >Add</v-btn
                  >
                  <v-btn
                    small
                    v-if="editAlarmConfiguration.action == 'EDIT'"
                    color="primary"
                    @click="UpdateAlarmConfigure()"
                    >Update</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            v-if="current_view == 'MAIN_VIEW'"
            small
            class="borderRadius text-capitalize"
            color="primary"
            outlined
            @click="dialogPlantTypeOfOrganizationEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >

          <v-btn
            small
            v-if="current_view == 'MAIN_VIEW'"
            class="borderRadius text-capitalize mr-2"
            color="primary"
            :loading="loading"
            :disabled="configure_plant.is_alarm_configured == true && alarm_configurations.length == 0"
            @click="validateMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllPlantTypes } from "@/mixins/GetAllPlantTypes.js";
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
import { editOrConfigureAlarmOfPlant } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogPlantTypeOfOrganization: Boolean,
    StoreAllCommonObject: Object,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllPlantTypes, GetAllDashboardParameter],
  data: () => ({
    Toggle: 0,
    current_view: "MAIN_VIEW",
    configure_plant: {
      plant_name: "",
      is_alarm_configured: false,
      rated_capacity: "",
      rated_hours_of_operation: "",
      time_zone: "",
    },
    AlarmWeightIntervals: [
      {
        text: "Minutes",
        value: "MINUTES",
      },
      {
        text: "Hours",
        value: "HOURS",
      },
      {
        text: "Days",
        value: "DAYS",
      },
      {
        text: "Months",
        value: "MONTHS",
      },
    ],
    MinutesInterval: [],
    HoursInterval: [],
    DaysInterval: [],
    MonthsInterval: [],
    YearsInterval: [],
    alarm_configurations_object: {
      configured_alarm_tag: "",
      alarm_delay: "",
      low_range_value: "",
      high_range_value: "",
      range_alarm_message: "",
      is_threshold_malfunction_enabled: false,
      low_set_point_greater_than: "",
      low_set_point_lesser_than: "",
      high_set_point_greater_than: "",
      malfunction_alarm_message: "",
      high_set_point_lesser_than: "",
      step_number: "",
      alarm_wait_time_config: "MINUTES",
      alarm_wait_time: "0",
    },
    alarmConfigurationHeaders: [
      { text: "Tag", value: "configured_alarm_tag" },
      { text: "Delay", value: "alarm_delay" },
      { text: "Alarm wait time config", value: "alarm_wait_time_config" },
      { text: "Alarm Wait Time", value: "alarm_wait_time" },
      { text: "Low Value", value: "low_range_value" },
      { text: "High Value", value: "high_range_value" },
      { text: "Alarm Message", value: "range_alarm_message" },
      { text: "Malfunctioned Enabled", value: "is_threshold_malfunction_enabled" },
      { text: "Low Set Point Greater Than", value: "low_set_point_greater_than" },
      { text: "Low Set Point Lesser Than", value: "low_set_point_lesser_than" },
      { text: "Malfunction Alarm Message", value: "malfunction_alarm_message" },
      { text: "High Set Point Greater Than", value: "high_set_point_greater_than" },
      { text: "High Set Point Lesser Than", value: "high_set_point_lesser_than" },
      { text: "Step No.", value: "step_number" },
      { text: "Action", value: "Action" },
    ],
    alarm_configurations: [],
    setRangeItems: ["LOW", "HIGH"],
    loading: false,
    SnackBarComponent: {},
    alarmDelayItems: [],
    plantStepItems: [],
    editAlarmConfiguration: {},
    operational_parameters_colors_settings: [],
    getHoursItems: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    timeZoneItems: [{ text: "Asia/Kolkata", value: "Asia/Kolkata" }],
    EditIndex: -1,
  }),
  watch: {
    "configure_plant.plant_name"(value) {
      if (value && value != "") {
        if (this.GetAllUFROTypesList.length != 0) {
          this.plantStepItems = this.GetAllUFROTypesList.filter(
            (plant) => plant.plant_name == this.configure_plant.plant_name
          )[0].plant_steps;
        }
      }
    },
    "configure_plant.is_alarm_configured"(value) {
      if (value == false) {
        this.configure_plant.is_threshold_malfunction_enabled = false;
      }
    },
    dialogPlantTypeOfOrganization(value) {
      if (value == true) {
        this.current_view = "MAIN_VIEW";
        this.GetAllPlantTypesMethod();
        this.GetAllDashboardParameterMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id,
          "AI"
        );
        if (this.StoreObj.action == "EDITPLANT") {
          this.configure_plant.plant_name = this.StoreObj.plant_name;
          this.configure_plant.is_alarm_configured = this.StoreObj.is_alarm_configured
            ? this.StoreObj.is_alarm_configured
            : false;
          this.alarm_configurations = this.StoreObj.alarm_configurations ? this.StoreObj.alarm_configurations : [];
          this.plantStepItems = this.StoreObj.plant_steps ? this.StoreObj.plant_steps : [];
          this.configure_plant.rated_capacity = this.StoreObj.rated_capacity;
          this.configure_plant.rated_hours_of_operation = this.StoreObj.rated_hours_of_operation
            ? this.StoreObj.rated_hours_of_operation.toString()
            : "";
          this.configure_plant.time_zone = this.StoreObj.time_zone;
        }
      }
    },
    "alarm_configurations_object.alarm_wait_time_config"(val) {
      if (val == "HOURS") {
        this.SetInterval();
      } else if (val == "DAYS") {
        this.SetInterval();
      } else if (val == "MONTHS") {
        this.SetInterval();
      } else if (val == "MINUTES") {
        this.SetInterval();
      }
    },
    current_view(val) {
      if (val == "CONFIGURE_ALARM") {
        if (this.editAlarmConfiguration.action == "EDIT") {
          this.alarm_configurations_object = {
            step_number: String(this.editAlarmConfiguration.step_number),
            configured_alarm_tag: this.editAlarmConfiguration.configured_alarm_tag,
            alarm_wait_time_config: this.editAlarmConfiguration.alarm_wait_time_config,
            alarm_wait_time:
              this.editAlarmConfiguration.alarm_wait_time_config == "MINUTES"
                ? this.editAlarmConfiguration.alarm_wait_time / 60
                : this.editAlarmConfiguration.alarm_wait_time_config == "DAYS"
                ? this.editAlarmConfiguration.alarm_wait_time / 86400
                : this.editAlarmConfiguration.alarm_wait_time_config == "MONTHS"
                ? this.editAlarmConfiguration.alarm_wait_time / 2592000
                : this.editAlarmConfiguration.alarm_wait_time_config == "HOURS"
                ? this.editAlarmConfiguration.alarm_wait_time / 3600
                : this.editAlarmConfiguration.alarm_wait_time,
            alarm_delay: this.editAlarmConfiguration.alarm_delay,
            low_range_value: this.editAlarmConfiguration.low_range_value,
            high_range_value: this.editAlarmConfiguration.high_range_value,
            range_alarm_message: this.editAlarmConfiguration.range_alarm_message,
            is_threshold_malfunction_enabled: this.editAlarmConfiguration.is_threshold_malfunction_enabled,
            low_set_point_greater_than: this.editAlarmConfiguration.low_set_point_greater_than,
            low_set_point_lesser_than: this.editAlarmConfiguration.low_set_point_lesser_than,
            high_set_point_lesser_than: this.editAlarmConfiguration.high_set_point_lesser_than,
            high_set_point_greater_than: this.editAlarmConfiguration.high_set_point_greater_than,
            malfunction_alarm_message: this.editAlarmConfiguration.malfunction_alarm_message,
          };
          // if (this.editAlarmConfiguration.is_threshold_malfunction_enabled == true) {
          //   this.alarm_configurations_object = {
          //     low_set_point_greater_than: this.editAlarmConfiguration.low_set_point_greater_than,
          //     low_set_point_lesser_than: this.editAlarmConfiguration.low_set_point_lesser_than,
          //     high_set_point_greater_than: this.editAlarmConfiguration.high_set_point_greater_than,
          //     high_set_point_lesser_than: this.editAlarmConfiguration.high_set_point_lesser_than,
          //     malfunction_alarm_message: this.editAlarmConfiguration.malfunction_alarm_message,
          //   };
          // }
          console.log("plantStepItems", this.plantStepItems);
        }
      } else if (val == "CONFIGURE_ALARM") {
        this.editAlarmConfiguration = {};
      }
    },
  },
  mounted() {
    this.SetInterval();
    this.alarmDelayItems = [];
    let i = 1;
    while (i <= 60) {
      this.alarmDelayItems.push(i);
      i++;
    }
  },
  methods: {
    CheckItem(item, action) {
      this.editAlarmConfiguration = item;
      this.editAlarmConfiguration.action = action;
      this.EditIndex = this.alarm_configurations.indexOf(item);
      this.alarm_configurations_object = Object.assign({}, item);
      this.current_view = "CONFIGURE_ALARM";
      console.log("Nanthu", this.editAlarmConfiguration);
    },
    UpdateAlarmConfigure() {
      this.pushSettingsToArray();
    },
    BackToMainPage() {
      this.alarm_configurations_object = {
        configured_alarm_tag: "",
        alarm_delay: "",
        low_range_value: "",
        high_range_value: "",
        range_alarm_message: "",
        is_threshold_malfunction_enabled: false,
        low_set_point_greater_than: "",
        low_set_point_lesser_than: "",
        high_set_point_greater_than: "",
        malfunction_alarm_message: "",
        high_set_point_lesser_than: "",
        step_number: "",
        alarm_wait_time_config: [],
        alarm_wait_time: [],
      };
      this.current_view = "MAIN_VIEW";
    },
    SetInterval() {
      if (this.alarm_configurations_object.alarm_wait_time_config == "MINUTES") {
        for (let i = 0; i <= 60; i++) {
          let Object = {
            text: `${i} Minutes`,
            value: i,
          };
          this.MinutesInterval.push(Object);
          console.log("MinutesInterval", this.MinutesInterval);
        }
      } else if (this.alarm_configurations_object.alarm_wait_time_config == "HOURS") {
        for (let i = 0; i <= 24; i++) {
          let Object = {
            text: `${i} Hours`,
            value: i,
          };
          this.HoursInterval.push(Object);
        }
      } else if (this.alarm_configurations_object.alarm_wait_time_config == "DAYS") {
        for (let i = 0; i <= 31; i++) {
          let Object = {
            text: `${i} Days`,
            value: i,
          };
          this.DaysInterval.push(Object);
        }
      } else if (this.alarm_configurations_object.alarm_wait_time_config == "MONTHS") {
        for (let i = 0; i <= 12; i++) {
          let Object = {
            text: `${i} Months`,
            value: i,
          };
          this.MonthsInterval.push(Object);
        }
      }
    },
    checkValidation() {
      if (
        parseFloat(this.alarm_configurations_object.low_range_value) >=
        parseFloat(this.alarm_configurations_object.high_range_value)
      ) {
        console.log("Check First");
        return false;
      }
      if (
        parseFloat(this.alarm_configurations_object.low_set_point_greater_than) <=
        parseFloat(this.alarm_configurations_object.low_range_value)
      ) {
        console.log("Check 1");
        console.log(this.alarm_configurations_object.low_set_point_greater_than);
        console.log(this.alarm_configurations_object.low_range_value);
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.low_set_point_greater_than) >=
        parseFloat(this.alarm_configurations_object.high_range_value)
      ) {
        console.log("Check 2");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.low_set_point_greater_than) >=
        parseFloat(this.alarm_configurations_object.low_set_point_lesser_than)
      ) {
        console.log("Check 3");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.high_set_point_greater_than) <=
        parseFloat(this.alarm_configurations_object.low_range_value)
      ) {
        console.log("Check 4");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.high_set_point_greater_than) >=
        parseFloat(this.alarm_configurations_object.high_range_value)
      ) {
        console.log("Check 5");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.high_set_point_greater_than) >=
        parseFloat(this.alarm_configurations_object.high_set_point_lesser_than)
      ) {
        console.log("Check 6");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.low_set_point_lesser_than) <=
        parseFloat(this.alarm_configurations_object.low_range_value)
      ) {
        console.log("Check 7");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.low_set_point_lesser_than) >=
        parseFloat(this.alarm_configurations_object.high_range_value)
      ) {
        console.log("Check 8");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.high_set_point_lesser_than) <=
        parseFloat(this.alarm_configurations_object.low_range_value)
      ) {
        console.log("Check 9");
        return false;
      } else if (
        parseFloat(this.alarm_configurations_object.high_set_point_lesser_than) >=
        parseFloat(this.alarm_configurations_object.high_range_value)
      ) {
        console.log("Check 10");
        return false;
      } else return true;
    },
    pushSettingsToArray() {
      if (this.$refs.alarmform.validate()) {
        if (this.checkValidation() == true) {
          if (this.alarm_configurations_object.kpch_tag) {
            delete this.alarm_configurations_object.kpch_tag;
          }
          this.alarm_configurations_object.low_range_value = parseFloat(
            this.alarm_configurations_object.low_range_value
          );
          this.alarm_configurations_object.high_range_value = parseFloat(
            this.alarm_configurations_object.high_range_value
          );
          this.alarm_configurations_object.low_set_point_greater_than != null
            ? parseFloat(this.alarm_configurations_object.low_set_point_greater_than)
            : undefined;
          this.alarm_configurations_object.low_set_point_lesser_than != null
            ? parseFloat(this.alarm_configurations_object.low_set_point_lesser_than)
            : undefined;
          this.alarm_configurations_object.high_set_point_greater_than != null
            ? parseFloat(this.alarm_configurations_object.high_set_point_greater_than)
            : undefined;
          this.alarm_configurations_object.high_set_point_lesser_than != null
            ? parseFloat(this.alarm_configurations_object.high_set_point_lesser_than)
            : undefined;
          if (this.alarm_configurations_object.alarm_wait_time_config == "MINUTES") {
            this.alarm_configurations_object.alarm_wait_time = parseInt(
              this.alarm_configurations_object.alarm_wait_time * 60
            );
          } else if (this.alarm_configurations_object.alarm_wait_time_config == "DAYS") {
            this.alarm_configurations_object.alarm_wait_time = parseInt(
              this.alarm_configurations_object.alarm_wait_time * 24 * 60 * 60
            );
          } else if (this.alarm_configurations_object.alarm_wait_time_config == "MONTHS") {
            this.alarm_configurations_object.alarm_wait_time = parseInt(
              this.alarm_configurations_object.alarm_wait_time * 30 * 24 * 60 * 60
            );
          } else if (this.alarm_configurations_object.alarm_wait_time_config == "HOURS") {
            this.alarm_configurations_object.alarm_wait_time = parseInt(
              this.alarm_configurations_object.alarm_wait_time * 60 * 60
            );
          }
          if (this.EditIndex > -1) {
            Object.assign(this.alarm_configurations[this.EditIndex], this.alarm_configurations_object);
            this.EditIndex = -1;
          } else {
            this.alarm_configurations.push(this.alarm_configurations_object);
          }
          this.alarm_configurations_object = {
            configured_alarm_tag: "",
            alarm_delay: "",
            low_range_value: "",
            high_range_value: "",
            range_alarm_message: "",
            is_threshold_malfunction_enabled: false,
            low_set_point_greater_than: "",
            low_set_point_lesser_than: "",
            high_set_point_greater_than: "",
            malfunction_alarm_message: "",
            high_set_point_lesser_than: "",
            step_number: "",
            alarm_wait_time_config: [],
            alarm_wait_time: [],
          };
          this.current_view = "MAIN_VIEW";
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Invalid Input",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Form Validation",
        };
      }
    },
    validateMethod() {
      if (this.configure_plant.is_alarm_configured == true) {
        if (this.alarm_configurations.length == 0) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please Configure Alarms",
          };
        } else {
          this.configurePlantTypesOfOrganizationValidateMethod();
        }
      } else if (this.configure_plant.is_alarm_configured == false) {
        this.configurePlantTypesOfOrganizationValidateMethod();
      }
    },
    configurePlantTypesOfOrganizationValidateMethod() {
      if (this.$refs.form.validate()) {
        this.configurePlantTypesOfOrganizationMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async configurePlantTypesOfOrganizationMethod() {
      try {
        var inputParams = {
          organization_id: this.StoreAllCommonObject.organization_id,
          asset_id: this.StoreAllCommonObject.asset_id,
          plant_name: this.configure_plant.plant_name,
          user_id: this.$store.getters.get_current_user_details.user_id,
          is_alarm_configured: this.configure_plant.is_alarm_configured
            ? this.configure_plant.is_alarm_configured
            : false,
          alarm_configurations:
            this.configure_plant.is_alarm_configured == true
              ? this.alarm_configurations.map((alarm_tag_object) => {
                  let object = alarm_tag_object;
                  delete alarm_tag_object.action;
                  if (object.kpch_tag) {
                    delete alarm_tag_object.kpch_tag;
                  }
                  return object;
                })
              : undefined,
          rated_capacity: parseFloat(this.configure_plant.rated_capacity),
          rated_hours_of_operation: Number(this.configure_plant.rated_hours_of_operation),
          time_zone: this.configure_plant.time_zone,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(editOrConfigureAlarmOfPlant, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.EditOrConfigureAlarmOfPlant);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogPlantTypeOfOrganizationEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogPlantTypeOfOrganizationEmit(Toggle) {
      this.current_view = "MAIN_VIEW";
      this.configure_plant.plant_name = "";
      this.alarm_configurations = [];
      this.configure_plant.is_alarm_configured = false;
      if (this.$refs.alarmform) {
        this.$refs.alarmform.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
