<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateOrganizationsDialog
      :StoreObj="StoreObj"
      :dialogCreateOrganization="dialogCreateOrganization"
      @clicked="createOrganizationsDialogEmit"
    />
    <CreatePlantDialog
      :StoreObj="StoreObj"
      :StoreAllCommonObject="StoreAllCommonObject"
      :dialogCreatePlant="dialogCreatePlant"
      @clicked="dialogCreatePlantEmit"
    />
    <ConfigurePlantTypeDialog
      :StoreObj="StoreObj"
      :StoreAllCommonObject="StoreAllCommonObject"
      :dialogPlantTypeOfOrganization="dialogPlantTypeOfOrganization"
      @clicked="dialogPlantTypeOfOrganizationEmit"
    />
    <ConfigureDashboardParameter
      :StoreObj="StoreObj"
      :current_view="current_view"
      :StoreAllCommonObject="StoreAllCommonObject"
      :dialogConfigureDashboardParameter="dialogConfigureDashboardParameter"
      @clicked="dialogConfigureDashboardParameterEmit"
    />
    <ActivateInactivatePlantTypeDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivatePlantType="dialogActivateInactivatePlantType"
      @clicked="dialogActivateInactivatePlantTypeEmit"
    />
    <RemoveDashboardTagDialog
      :dialogRemoveDashboardTag="dialogRemoveDashboardTag"
      :StoreDeleteObj="StoreDeleteObj"
      @clicked="dialogRemoveDashboardTagEmit"
    />
    <CreateAssets
      :dialogCreateAsset="dialogCreateAsset"
      :StoreAssetObj="StoreAssetObj"
      :StoreObj="StoreObj"
      :StoreAllCommonObject="StoreAllCommonObject"
      @clicked="createAssetDialogEmit"
    />
    <DeleteAssets
      :dialogActivateInactivateAsset="dialogActivateInactivateAsset"
      :StoreObj="StoreObj"
      :StoreAssetObj="StoreAssetObj"
      @clicked="dialogActivateInactivateAssetEmit"
    />
    <PreviewDialog
      :PreviewDialog="PreviewDialog"
      :StoreAllCommonObject="StoreAllCommonObject"
      @clicked="PreviewDialogEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="5">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor mr-3">Organizations</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="7">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              class="field_height field_label_size FontSize mt-8 mr-2 maxWidthLarge"
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-btn
              v-if="current_view == 'ASSET'"
              small
              color="primary"
              class="borderRadius"
              @click="(dialogCreateAsset = true), (StoreObj.action = 'CREATE')"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create Asset</v-btn
            >
            <v-select
              v-if="current_view == 'CONFIGUREBOARD' && tag_type == 'DI'"
              dense
              outlined
              class="field_height field_label_size maxWidth FontSize mt-8 mr-2"
              label="Status Or Feedback"
              :items="statusFeedbackItems"
              item-text="text"
              item-value="value"
              v-model="status_or_feedback"
            ></v-select>
            <v-btn
              v-if="current_view == 'ORGANIZATION'"
              small
              color="primary"
              @click="(dialogCreateOrganization = true), (StoreObj.action = 'CREATE')"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
            >
            <v-btn
              v-if="current_view == 'PLANT' && plantViewTab == 0"
              small
              color="primary"
              @click="(dialogCreatePlant = true), (StoreObj.action = 'CREATE')"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create Plant</v-btn
            >
            <v-btn
              v-if="current_view == 'PLANT' && plantViewTab == 0"
              small
              color="primary"
              class="ml-3"
              @click="OpenPreviewDialogMethod()"
              >Preview</v-btn
            >
            <!-- <v-btn
              v-if="current_view == 'PLANT' && plantViewTab == 0"
              small
              color="primary"
              @click="(dialogCreatePlant = true), (StoreObj.action = 'CREATE')"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Preview</v-btn
            > -->
            <v-btn
              v-if="
                current_view == 'CONFIGUREBOARD' || (current_view == 'PLANT' && plantViewTab == 1) || plantViewTab == 2
              "
              small
              color="primary"
              @click="
                (dialogConfigureDashboardParameter = true),
                  (StoreObj.action = 'CREATE'),
                  (StoreObj.plantTYpe = `${plantViewTab == 1 ? 'AI' : plantViewTab == 2 ? 'AO' : ''}`)
              "
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-cog</v-icon>Add Tag</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-breadcrumbs class="pl-4 my-n5" :items="breadCrumbsArray" divider="-" style="cursor: pointer">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click="checkBreadCrumbsItem(item)">
            <v-icon color="primary" v-if="breadCrumbsArray.indexOf(item) == 0">mdi-home</v-icon>
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tabs v-if="current_view == 'PLANT'" dense class="pl-4 ma-0" v-model="plantViewTab">
        <v-tab class="text-capitalize">Plants</v-tab>
        <v-tab class="text-capitalize">Operational Parameters</v-tab>
        <v-tab class="text-capitalize">Set Points</v-tab>
      </v-tabs>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :search="search"
          :headers="
            current_view == 'ORGANIZATION'
              ? organizationHeaders
              : current_view == 'ASSET'
              ? assetHeaders
              : current_view == 'PLANT'
              ? plantViewTab == 0
                ? plantHeaders
                : dashboardItemsHeaders
              : dashboardItemsHeaders
          "
          :items="
            current_view == 'ORGANIZATION'
              ? GetAllOrganizationList
              : current_view == 'ASSET'
              ? GetAllAssetsAcrossOrganizationList
              : current_view == 'PLANT'
              ? plantViewTab == 0
                ? GetAllPlantsForOrganizationList
                : GetAllDashboardParameterList
              : current_view == 'CONFIGUREBOARD'
              ? GetAllDashboardParameterList
              : []
          "
          @click:row="checkRowItem"
          :no-data-text="overlay == false ? noDataText : 'Please wait while the data is loading...'"
        >
          <template v-slot:[`item.organization_name`]="{ item }">
            <div class="FontSize">
              {{ item.organization_name }}
            </div>
          </template>
          <template v-slot:[`item.organization_created_on`]="{ item }">
            <div class="FontSize">
              {{ item.organization_created_on ? new Date(item.organization_created_on).toLocaleString() : "-" }}
            </div>
          </template>
          <template v-slot:[`item.contact_person_user_name`]="{ item }">
            <div class="FontSize">
              {{ item.contact_person_user_name ? item.contact_person_user_name : "-" }}
            </div>
          </template>
          <template v-slot:[`item.contact_person_email_id`]="{ item }">
            <div class="FontSize">
              {{ item.contact_person_email_id ? item.contact_person_email_id : "-" }}
            </div>
          </template>
          <template v-slot:[`item.contact_person_contact_number`]="{ item }">
            <div class="FontSize">
              {{ item.contact_person_country_code }}-{{
                item.contact_person_contact_number ? item.contact_person_contact_number : "-"
              }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkItem(item, 'EDIT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span>EDIT</span>
            </v-tooltip>
            <v-tooltip bottom v-if="current_view == 'ORGANIZATION'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkItem(item, 'DELETEORG')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Delete ${item.organization_name}`"></span>
            </v-tooltip>
          </template>
          <!-- Columns Of All The Headers Of Organization Ends Here -->
          <template v-slot:[`item.asset_name`]="{ item }">
            <div class="FontSize">{{ item.asset_name }}</div>
          </template>
          <template v-slot:[`item.asset_created_on`]="{ item }">
            <div class="FontSize">{{ new Date(item.asset_created_on).toLocaleString() }}</div>
          </template>
          <template v-slot:[`item.asset_status`]="{ item }">
            <div
              class="FontSize"
              :class="
                item.asset_status == 'ACTIVE'
                  ? 'GreenHeader'
                  : item.asset_status == 'DASHBOARD_CONFIGURATION_PENDING'
                  ? 'BlueHeader'
                  : 'RedHeader'
              "
            >
              {{
                item.asset_status == "ACTIVE"
                  ? "ACTIVE"
                  : item.asset_status == "DASHBOARD_CONFIGURATION_PENDING"
                  ? "Dashboard Not Configured"
                  : "INACTIVE"
              }}
            </div>
          </template>
          <template v-slot:[`item.ActionA`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkItem(item, 'EDITASSET')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.asset_name}`"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkItem(item, 'DELETEASSETS')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Delete ${item.asset_name}`"></span>
            </v-tooltip>
          </template>
          <!-- Columns Of All The Headers Of Assets Ends Here -->
          <template v-slot:[`item.plant_name`]="{ item }">
            <div class="FontSize">{{ item.plant_name }}</div>
          </template>
          <template v-slot:[`item.plant_tag_id`]="{ item }">
            <div class="FontSize">{{ item.plant_tag_id }}</div>
          </template>
          <template v-slot:[`item.plant_created_on`]="{ item }">
            <div class="FontSize">{{ new Date(item.plant_created_on).toLocaleString() }}</div>
          </template>
          <template v-slot:[`item.ActionP`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkItem(item, 'EDITPLANT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.plant_name}`"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkItem(item, 'DELETEASSET')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Delete ${item.plant_name}`"></span>
            </v-tooltip>
          </template>
          <!-- Columns Of All The Headers Of Plants Ends Here -->
          <template v-slot:[`item.tag_type`]="{ item }">
            <div class="FontSize">
              {{ item.tag_type }}
            </div>
          </template>
          <template v-slot:[`item.tag_id`]="{ item }">
            <div class="FontSize">
              {{ item.tag_id }}
            </div>
          </template>
          <template v-slot:[`item.tag_description`]="{ item }">
            <div class="FontSize">
              {{ item.tag_description }}
            </div>
          </template>
          <template v-slot:[`item.sort_count`]="{ item }">
            <div class="FontSize">
              {{ item.sort_count }}
            </div>
          </template>
          <template v-slot:[`item.ActionD`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click.stop="checkItem(item, 'EDITTAG')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span>Edit {{ item.tag_description }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click.stop="checkItem(item, 'DELETE')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span>Delete {{ item.tag_description }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllOrganizations } from "@/mixins/GetAllOrganizations.js";
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
import { GetAllPlantsForOrganization } from "@/mixins/GetAllPlantsForOrganization.js";
import { GetAllAssetsAcrossOrganization } from "@/mixins/GetAllAssetsAcrossOrganization.js";
import Overlay from "@/components/Extras/Overlay.vue";
import CreateAssets from "@/components/Organizations/Dialogs/CreateAssetDialog.vue";
import DeleteAssets from "@/components/Organizations/Dialogs/ActivateInactivateAssetDialog.vue";
import CreatePlantDialog from "@/components/Organizations/Dialogs/CreatePlantDialog.vue";
import RemoveDashboardTagDialog from "@/components/Organizations/Dialogs/RemoveDashboardTagDialog";
import ConfigurePlantTypeDialog from "@/components/Organizations/Dialogs/ConfigurePlantTypeDialog.vue";
import CreateOrganizationsDialog from "@/components/Organizations/Dialogs/CreateOrganizationsDialog.vue";
import ConfigureDashboardParameter from "@/components/Organizations/Dialogs/ConfigureDashboardParameter.vue";
import ActivateInactivatePlantTypeDialog from "@/components/PlantTypes/Dialogs/ActivateInactivatePlantTypeDialog.vue";
import PreviewDialog from "@/components/Organizations/Dialogs/PreviewDialog.vue";
export default {
  components: {
    ActivateInactivatePlantTypeDialog,
    ConfigureDashboardParameter,
    CreateOrganizationsDialog,
    RemoveDashboardTagDialog,
    ConfigurePlantTypeDialog,
    CreatePlantDialog,
    Overlay,
    CreateAssets,
    DeleteAssets,
    PreviewDialog,
  },
  mixins: [GetAllOrganizations, GetAllDashboardParameter, GetAllPlantsForOrganization, GetAllAssetsAcrossOrganization],
  props: {},
  data: () => ({
    // Strings
    search: "",
    noDataText: "",
    plantViewTab: 0,
    current_view: "ORGANIZATION",
    tag_type: "DI",
    status_or_feedback: "STATUS",
    plant_name: "",
    // Booleans
    overlay: false,
    PreviewDialog: false,
    dialogCreatePlant: false,
    dialogRemoveDashboardTag: false,
    dialogCreateOrganization: false,
    dialogPlantTypeOfOrganization: false,
    dialogActivateInactivatePlantType: false,
    dialogConfigureDashboardParameter: false,
    dialogCreateAsset: false,
    dialogActivateInactivateAsset: false,
    // Arrays
    // Headers
    dashboardItemsHeaders: [
      { text: "Type", value: "tag_type" },
      { text: "Tag ID", value: "tag_id" },
      { text: "Tag Name", value: "tag_description" },
      { text: "Weightage", value: "sort_count" },
      { text: "Action", value: "ActionD" },
    ],
    organizationHeaders: [
      {
        text: "Organization Name",
        value: "organization_name",
      },
      {
        text: "Created On",
        value: "organization_created_on",
      },
      {
        text: "Contact Person",
        value: "contact_person_user_name",
      },
      {
        text: "Email ID",
        value: "contact_person_email_id",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    assetHeaders: [
      { text: "Name", value: "asset_name" },
      { text: "Created On", value: "asset_created_on" },
      { text: "Status", value: "asset_status" },
      { text: "Action", value: "ActionA" },
    ],
    plantHeaders: [
      { text: "Name", value: "plant_name" },
      { text: "Tag ID", value: "plant_tag_id" },
      { text: "Created On", value: "plant_created_on" },
      { text: "Action", value: "ActionP" },
    ],
    tagTypeItems: [
      { text: "Operational", value: "AI" },
      { text: "Summary", value: "DI" },
    ],
    statusFeedbackItems: [
      { text: "Status", value: "STATUS" },
      { text: "Feedback", value: "FEEDBACK" },
    ],
    // Items
    GetAllOrganizationList: [],
    GetAllDashboardParameterList: [],
    GetAllPlantsForOrganizationList: [],
    GetAllAssetsAcrossOrganizationList: [],
    // Other Arrays
    breadCrumbsArray: [
      {
        text: "Home",
        current_view: "ORGANIZATION",
      },
    ],
    // Objects
    StoreAllCommonObject: {},
    StoreAssetobject: {},
    StoreDeleteObj: {},
    StoreObj: {},
    StoreAssetObj: {},
  }),
  watch: {
    status_or_feedback() {
      this.GetAllDashboardParameterList = this.sortMethod(
        this.GetAllDashboardParameterListOverall.filter(
          (item) =>
            item.tag_type == this.tag_type &&
            item.status_or_feedback == this.status_or_feedback &&
            item.plant_name == this.plant_name
        )
      );
    },
    plantViewTab(value) {
      if (value == 1) {
        this.GetAllDashboardParameterMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id,
          "AI"
        );
      } else if (value == 2) {
        this.GetAllDashboardParameterMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id,
          "AO"
        );
      }
      console.log("check value", this.current_view);
    },
    current_view() {
      this.plantViewTab = 0;
    },
  },
  mounted() {
    this.GetAllOrganizationsMethod();
  },
  methods: {
    PreviewDialogEmit() {
      this.PreviewDialog = false;
    },
    dialogRemoveDashboardTagEmit(Toggle) {
      this.dialogRemoveDashboardTag = false;
      if (Toggle == 2) {
        this.GetAllDashboardParameterMethod(
          this.StoreDeleteObj.organization_id,
          this.StoreDeleteObj.asset_id,
          this.current_view == "PLANT" && this.plantViewTab == 1
            ? "AI"
            : this.current_view == "PLANT" && this.plantViewTab == 2
            ? "AO"
            : "DI"
        );
      }
    },
    dialogConfigureDashboardParameterEmit(Toggle) {
      this.dialogConfigureDashboardParameter = false;
      if (Toggle == 2) {
        this.GetAllDashboardParameterMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id,
          this.current_view == "PLANT" && this.plantViewTab == 1
            ? "AI"
            : this.current_view == "PLANT" && this.plantViewTab == 2
            ? "AO"
            : "DI"
        );
      }
    },
    dialogActivateInactivatePlantTypeEmit(Toggle) {
      this.dialogActivateInactivatePlantType = false;
      if (Toggle == 2) {
        this.GetAllPlantsForOrganizationMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id
        );
      }
    },
    dialogPlantTypeOfOrganizationEmit() {
      this.dialogPlantTypeOfOrganization = false;
      this.GetAllPlantsForOrganizationMethod(
        this.StoreAllCommonObject.organization_id,
        this.StoreAllCommonObject.asset_id
      );
    },
    dialogCreatePlantEmit(Toggle) {
      this.dialogCreatePlant = false;
      if (Toggle == 2) {
        this.GetAllPlantsForOrganizationMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id
        );
      }
    },
    checkBreadCrumbsItem(item) {
      this.breadCrumbsArray.splice(this.breadCrumbsArray.indexOf(item) + 1);
      switch (item.current_view) {
        case "ORGANIZATION":
          this.GetAllOrganizationsMethod();
          this.current_view = "ORGANIZATION";
          break;
        case "ASSET":
          this.current_view = "ASSET";
          this.StoreAssetobject = item;
          this.GetAllAssetsAcrossOrganizationMethod(item.key);
          break;
        case "PLANT":
          this.current_view = "PLANT";
          break;
      }
    },
    createOrganizationsDialogEmit(Toggle) {
      this.dialogCreateOrganization = false;
      if (Toggle == 2) {
        this.GetAllOrganizationsMethod();
      }
    },
    checkItem(item, ACTION) {
      if (ACTION == "EDIT") {
        this.StoreObj = item;
        this.StoreObj.action = ACTION;
        this.dialogCreateOrganization = true;
      } else if (ACTION == "ACTION") {
        this.StoreObj = item;
        this.StoreObj.action = ACTION;
        this.dialogActivateInactivateOrganization = true;
      } else if (ACTION == "DELETE") {
        this.StoreDeleteObj = item;
        this.dialogRemoveDashboardTag = true;
      } else if (ACTION == "DELETEORG") {
        this.StoreObj = item;
        this.StoreObj.action = ACTION;
        this.dialogActivateInactivateOrganization = true;
      } else if (ACTION == "EDITTAG") {
        this.StoreObj = item;
        this.StoreObj.action = "EDIT";
        this.dialogConfigureDashboardParameter = true;
      } else if (ACTION == "DELETEASSET") {
        this.StoreObj = item;
        this.StoreObj.action = "DELETEASSET";
        this.dialogActivateInactivatePlantType = true;
      } else if (ACTION == "EDITPLANT") {
        this.StoreObj = item;
        this.StoreObj.action = "EDITPLANT";
        this.dialogPlantTypeOfOrganization = true;
      } else if (ACTION == "EDITASSET") {
        this.StoreObj = item;
        this.StoreObj.action = "EDITASSET";
        this.dialogCreateAsset = true;
      } else if (ACTION == "DELETEASSETS") {
        this.StoreObj = item;
        this.StoreObj.action = "DELETEASSETS";
        this.dialogActivateInactivateAsset = true;
      }
    },
    createAssetDialogEmit(Toggle) {
      this.dialogCreateAsset = false;
      if (Toggle == 2) {
        this.GetAllAssetsAcrossOrganizationMethod(this.StoreAllCommonObject.organization_id);
      }
    },
    dialogActivateInactivateAssetEmit(Toggle) {
      this.dialogActivateInactivateAsset = false;
      if (Toggle == 2) {
        this.GetAllAssetsAcrossOrganizationMethod(this.StoreAllCommonObject.organization_id);
      }
    },
    OpenPreviewDialogMethod() {
      this.PreviewDialog = true;
      console.log("THis.StoreAllCommonObject", this.StoreAllCommonObject);
      this.PreviewDialog = true;
    },
    checkRowItem(item) {
      this.StoreAllCommonObject = item;
      switch (this.current_view) {
        case "ORGANIZATION":
          this.current_view = "ASSET";
          this.breadCrumbsArray.push({
            text: item.organization_name,
            key: item.organization_id,
            current_view: this.current_view,
          });
          this.GetAllAssetsAcrossOrganizationMethod(item.organization_id);
          break;
        case "ASSET":
          this.current_view = "PLANT";
          this.StoreAssetobject = item;
          this.breadCrumbsArray.push({ text: item.asset_name, key: item.asset_id, current_view: this.current_view });
          this.GetAllPlantsForOrganizationMethod(item.organization_id, item.asset_id);
          break;
        case "PLANT":
          if (this.plantViewTab == 0) {
            this.current_view = "CONFIGUREBOARD";
            this.breadCrumbsArray.push({
              text: item.plant_name,
              key: item.plant_tag_id,
              asset_id: item.asset_id,
              current_view: this.current_view,
            });
            this.status_or_feedback = "STATUS";
            this.plant_name = item.plant_name;
            this.GetAllDashboardParameterMethod(item.organization_id, item.asset_id, "DI");
          }

          break;
      }
    },
  },
};
</script>
