import { API } from "aws-amplify";
export const GetAllDashboardParameter = {
  data() {
    return {
      GetAllDashboardParameterList: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetAllDashboardParameterMethod(organization_id, asset_id) {
      var self = this;
      if (self.$route.name != "Dashboard") {
        self.overlay = true;
      }
      const path = "listtagsoforganization";
      const myInit = {
        body: {
          organization_id: organization_id,
          asset_id: asset_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          console.log("response type", response);
          if (!response.errorType) {
            self.GetAllDashboardParameterList = response.data.items;
            if (self.$route.name == "OperationalParameter") {
              self.overlay = false;
            }
          } else {
            self.GetAllDashboardParameterList = [];
            self.noDataText = response.errorMessage;
            self.overlay = false;
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
