import { listTagsOfOrganization } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllDashboardParameter = {
  data() {
    return {
      noDataText: "",
      overlay: false,
      GetAllDashboardParameterList: [],
      GetAllDashboardParameterListOverall: [],
    };
  },
  methods: {
    async GetAllDashboardParameterMethod(organization_id, asset_id, tag_type) {
      console.log(tag_type);
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listTagsOfOrganization, {
            input: {
              organization_id,
              asset_id,
            },
          })
        );
        this.GetAllDashboardParameterListOverall = JSON.parse(result.data.ListTagsOfOrganization).data.items;
        this.GetAllDashboardParameterList = this.sortMethod(
          (result = JSON.parse(result.data.ListTagsOfOrganization).data.items.filter((item) =>
            tag_type == "DI" ? item.tag_type == tag_type && item.status_or_feedback == this.status_or_feedback && item.plant_name == this.plant_name : item.tag_type == tag_type
          ))
        );
        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.overlay = false;
        this.noDataText = error.errors[0].message;
        this.GetAllDashboardParameterList = [];
      }
    },
    sortMethod(array) {
      console.log("Going Inside Sort Method", array);
      return array.sort((a, b) => a.sort_count - b.sort_count);
    },
  },
};
