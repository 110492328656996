<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateAsset">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create Asset</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createAssetDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-text-field
              dense
              outlined
              label="Asset Name"
              class="field_height field_label_size FontSize"
              :rules="[(v) => !!v || 'Required']"
              v-model="asset_name"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              label="Asset Type"
              class="field_height field_label_size FontSize"
              :rules="[(v) => !!v || 'Required']"
              v-model="asset_type"
            ></v-text-field>
            <v-select
              outlined
              dense
              label="Select Template"
              class="field_height field_label_size FontSize"
              :items="TemplateItems"
              v-model="template_type"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createAssetDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createAsset, updateAsset } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateAsset: Boolean,
    StoreAssetObj: Object,
    StoreObj: Object,
    StoreAllCommonObject: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    asset_type: "",
    template_type: "",
    AssetTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "Super Admin", value: "SUPER_ADMIN" },
    ],
    loading: false,
    asset_name: "",
    SnackBarComponent: {},
    TemplateItems: [
      {
        text: "Template 1",
        value: "1",
      },
      {
        text: "Template 2",
        value: "2",
      },
      {
        text: "Template 3",
        value: "3",
      },
    ],
  }),
  watch: {
    dialogCreateAsset(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDITASSET") {
          console.log("Edit Asset", this.StoreObj);
          this.asset_name = this.StoreObj.asset_name;
          this.asset_type = this.StoreObj.asset_type;
          this.template_type = this.StoreObj.template_id; 
        }
      }
    },
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createAssetMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async createAssetMethod() {
      console.log("case", this.StoreAllCommonObject);

      var mutationname = null;
      var mutationresult = null;
      switch (this.StoreObj.action) {
        case "CREATE":
          mutationname = createAsset;
          mutationresult = "CreateAsset";
          var inputParams = {
            asset_name: this.asset_name,
            asset_type: this.asset_type,
            organization_id: this.StoreAllCommonObject.organization_id,
            template_id: this.template_type,
          };
          break;
        case "EDITASSET":
          inputParams = {
            asset_name: this.asset_name,
            asset_id: this.StoreObj.asset_id,
            asset_type: this.asset_type,
            organization_id: this.StoreObj.organization_id,
            template_id: this.template_type,
          };
          mutationname = updateAsset;
          mutationresult = "UpdateAsset";
          break;
      }

      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createAssetDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createAssetDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
