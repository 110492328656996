import { listPlantTypesOfOrganization } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllPlantsForOrganization = {
  data() {
    return {
      overlay: false,
      GetAllPlantsForOrganizationList: [],
    };
  },
  methods: {
    async GetAllPlantsForOrganizationMethod(organization_id, asset_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listPlantTypesOfOrganization, {
            input: {
              organization_id,
              asset_id,
            },
          })
        );
        this.GetAllPlantsForOrganizationList = JSON.parse(result.data.ListPlantTypesOfOrganization).data.items;
        this.overlay = false;
      } catch (error) {
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
