<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogCreatePlant" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>Create Plant</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreatePlantEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12">
                <v-select
                  dense
                  label="Plant Name"
                  outlined
                  class="field_height field_label_size FontSize"
                  v-model="configure_plant.plant_name"
                  :items="GetAllUFROTypesList"
                  item-text="plant_name"
                  item-value="plant_name"
                  :rules="[(v) => !!v || 'Required']"
                ></v-select>
                <v-text-field
                  dense
                  label="Rated Capacity"
                  outlined
                  class="field_height field_label_size FontSize"
                  v-model="configure_plant.rated_capacity"
                  :rules="[(v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[0-9.]{0,64}$/.test(v) || 'Numeric only']"
                ></v-text-field>
                <v-select
                  dense
                  label="Hours Of Operations"
                  outlined
                  class="field_height field_label_size FontSize"
                  v-model="configure_plant.rated_hours_of_operation"
                  :items="getHoursItems"
                  :rules="[(v) => !!v || 'Required']"
                ></v-select>
                <v-select
                  dense
                  label="Time Zone"
                  outlined
                  class="field_height field_label_size FontSize"
                  v-model="configure_plant.time_zone"
                  :items="timeZoneItems"
                  item-text="text"
                  item-value="value"
                  :rules="[(v) => !!v || 'Required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogCreatePlantEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="validateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllPlantTypes } from "@/mixins/GetAllPlantTypes.js";
import { createPlantOfAsset } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreatePlant: Boolean,
    StoreAllCommonObject: Object,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllPlantTypes],
  data: () => ({
    Toggle: 0,
    configure_plant: {
      plant_name: "",
      rated_capacity: "",
      rated_hours_of_operation: "",
      time_zone: "",
    },
    GetAllUFROTypesList: [],
    loading: false,
    SnackBarComponent: {},
    getHoursItems: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
    timeZoneItems: [{ text: "Asia/Kolkata", value: "Asia/Kolkata" }],
  }),
  watch: {
    dialogCreatePlant(value) {
      if (value == true) {
        this.GetAllPlantTypesMethod();
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.createPlantMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select Plant Name",
        };
      }
    },
    async createPlantMethod() {
      try {
        var inputParams = {
          organization_id: this.StoreAllCommonObject.organization_id,
          asset_id: this.StoreAllCommonObject.asset_id,
          plant_name: this.configure_plant.plant_name,
          user_id: this.$store.getters.get_current_user_details.user_id,
          rated_capacity: parseFloat(this.configure_plant.rated_capacity),
          rated_hours_of_operation: Number(this.configure_plant.rated_hours_of_operation),
          time_zone: this.configure_plant.time_zone,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(createPlantOfAsset, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreatePlantOfAsset);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogCreatePlantEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Get The Error", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogCreatePlantEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
