<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="800px" height="640" v-model="dialogCreateOrganization">
      <v-card height="640">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create Organization</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createOrganizationsDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-form ref="form" class="mt-4">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pb-0">
                    <v-text-field
                      dense
                      outlined
                      label="Parent Organization Name"
                      class="field_height field_label_size FontSize"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="create_organization.parent_organization_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pl-3 pb-0">
                    <v-text-field
                      dense
                      outlined
                      label="Parent Organization Code"
                      class="field_height field_label_size FontSize"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="create_organization.parent_organization_code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Organization Name"
                      class="field_height field_label_size FontSize"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="create_organization.organization_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pl-3">
                    <v-text-field
                      dense
                      outlined
                      label="Organization Code"
                      class="field_height field_label_size FontSize"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="create_organization.organization_code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Number Of Assets"
                      class="field_height field_label_size FontSize"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Numeric only',
                      ]"
                      v-model="create_organization.organization_number_of_assets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="pl-3">
                    <v-text-field
                      dense
                      outlined
                      label="Number Of Users"
                      class="field_height field_label_size FontSize"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Numeric only',
                      ]"
                      v-model="create_organization.organization_number_of_users"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="font-weight-bold mb-3">Contact Person Details</div>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size fontSize mr-2"
                      placeholder="Name"
                      :readonly="StoreObj.action == 'EDIT'"
                      v-model="create_organization.contact_person_user_name"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^(?=[a-z A-Z0-9@#._%+-]{0,30}$)[a-z A-Z]{0,64}$/.test(v) || 'Alphabets only',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size fontSize"
                      placeholder="Email Id"
                      :readonly="StoreObj.action == 'EDIT'"
                      v-model="create_organization.contact_person_email_id"
                      :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                    <v-select
                      dense
                      outlined
                      class="field_height field_label_size fontSize"
                      placeholder="Country Code"
                      :items="CountryList"
                      item-text="dial_code"
                      item-value="dial_code"
                      v-model="create_organization.contact_person_country_code"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      :readonly="StoreObj.action == 'EDIT'"
                      class="field_height field_label_size fontSize"
                      placeholder="Contact  Number"
                      v-model="create_organization.contact_person_contact_number"
                      :rules="[
                        (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Numeric only',
                        (v) => (v && v.length > 9) || 'Minimum 10 Digits',
                        (v) => (v && v.length < 11) || 'Maximum 10 Digits',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="font-weight-bold mb-3">Heatbeat Settings</div>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-select
                    dense
                    outlined
                    class="field_height field_label_size fontSize"
                    label="Min. Time"
                    :items="MinMaxItems"
                    v-model="create_organization.minimum_minutes"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-select
                    dense
                    outlined
                    class="field_height field_label_size fontSize"
                    label="Max. Time"
                    :items="MinMaxItems"
                    v-model="create_organization.maximum_minutes"
                  ></v-select>
                </v-col>
              </v-row>
              <div class="font-weight-bold mb-3">Organization Logo Shape</div>
              <v-select
                dense
                outlined
                class="field_height field_label_size fontSize"
                label="Shape"
                :items="LogoShapes"
                v-model="organization_logo_shape"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="text-center mt-4 pt-2">
              <v-card outlined class="elevation-0 text-center" height="150" v-if="imageUrl == ''">
                <v-label for="FileInput" class="mt-16">
                  <v-icon class="mt-8" size="40">mdi-cloud-upload</v-icon>
                  <div>Upload Brand Logo in PNG</div>
                </v-label>
                <input type="file" id="FileInput" accept="image/png" @change="onChange" hidden />
              </v-card>
              <v-card outlined class="elevation-0 pt-12" height="150" v-if="imageUrl != ''">
                <v-btn
                  absolute
                  color="red"
                  class="mt-3"
                  v-if="imageUrl != ''"
                  icon
                  fab
                  right
                  top
                  @click.stop="imageUrl = ''"
                  ><v-icon>mdi-close-circle</v-icon></v-btn
                >
                <v-img contain height="100" :src="imageUrl"> </v-img>
              </v-card>
              <v-card class="elevation-0 mt-1 pt-3" align="center" outlined>
                <v-color-picker v-model="organization_color_code" mode="hexa"></v-color-picker>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>

          <v-btn
            small
            class="borderRadius"
            outlined
            color="primary"
            @click="createOrganizationsDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createOrganization, updateOrganization } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { ChangeFileSize } from "@/mixins/ChangeFileSize.js";
var AWS = require("aws-sdk");
export default {
  props: {
    dialogCreateOrganization: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [ChangeFileSize],
  data: () => ({
    height: window.innerHeight,
    Imgsrc: "",
    image: null,
    imageUrl: "",
    organization_color_code: "#000000",
    Toggle: 0,
    type: "hex",
    user_type: "",
    nanthu: "",
    loading: false,
    hasImage: false,
    organization_logo_shape: "",
    Organization_logo: "",
    LogoShapes: [
      {
        text: "SQUARE",
        value: "SQUARE",
      },
      {
        text: "CIRCLE",
        value: "CIRCLE",
      },
      {
        text: "Rectangle",
        value: "RECTANGLE",
      },
    ],
    create_organization: {
      organization_name: "",
      organization_code: "",
      parent_organization_name: "",
      parent_organization_code: "",
      organization_number_of_assets: "",
      organization_number_of_users: "",
      contact_person_user_name: "",
      contact_person_email_id: "",
      contact_person_country_code: "+91",
      contact_person_contact_number: "",
      minimum_minutes: "",
      maximum_minutes: "",
    },
    MinMaxItems: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
      60,
    ],
    NoOfEmployees: ["1 to 100", "101 to 1000", "10001 to 10000", "10000+"],
    CountryList: [
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "USA",
        dial_code: "+1",
        code: "CA",
      },
    ],
    SnackBarComponent: {},
  }),

  mounted() {},
  watch: {
    dialogCreateOrganization(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.hasImage = false;
          console.log("StoreObj", this.StoreObj);
          this.create_organization = {
            parent_organization_name: this.StoreObj.parent_organization_name,
            parent_organization_code: this.StoreObj.parent_organization_code,
            organization_name: this.StoreObj.organization_name,
            organization_code: this.StoreObj.organization_code,
            organization_number_of_assets: this.StoreObj.organization_number_of_assets,
            organization_number_of_users: this.StoreObj.organization_number_of_users,
            contact_person_user_name: this.StoreObj.contact_person_user_name,
            contact_person_email_id: this.StoreObj.contact_person_email_id,
            contact_person_country_code: this.StoreObj.contact_person_country_code,
            contact_person_contact_number: this.StoreObj.contact_person_contact_number,
            minimum_minutes: this.StoreObj.minimum_minutes,
            maximum_minutes: this.StoreObj.maximum_minutes,
          };
          this.imageUrl = this.StoreObj.organization_logo_url;
          this.organization_color_code = this.StoreObj.organization_color_code;
          this.organization_logo_shape = this.StoreObj.organization_logo_shape;
        }
      }
    },
  },
  methods: {
    onChange(e) {
      console.log("ImageE", e);
      this.changeFileSize(e);
      this.image = e.target.files[0];
      this.hasImage = true;
    },
    async UploadOrganization(dataUrl) {
      this.imageUrl = dataUrl;
      console.log("ImageUrl", this.imageUrl);
      console.log("DataURL", dataUrl);
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.bucket_region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_access_key,
      });
      let file_name = this.image.name;

      var base64FileContent = dataUrl.split(",");
      var buf = Buffer.from(base64FileContent[1], "base64");
      var param = {
        Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
        Key: file_name,
        Body: buf,
        ContentType: "Image/type",
        ACL: "public-read-write",
      };
      await s3Bucket.putObject(param, function (err, data) {
        if (err) {
          console.log(err);
        } else if (data) {
          self.$store.commit("SET_IMAGE_URL", `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`);
          // self.createOrganizationMethod("EDIT", `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`);
        }
      });
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        // this.createOrganizationMethod();
        // this.createOrganizationMethod(this.StoreObj.action);
        switch (this.StoreObj.action) {
          case "CREATE":
            if (this.hasImage == false) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Please Upload Organization  Logo",
              };
            } else if (this.hasImage == true) {
              console.log("test");
              this.createOrganizationMethod(this.StoreObj.action);
            }
            break;
          case "EDIT":
            this.createOrganizationMethod(this.StoreObj.action);
            break;
        }
        // if(this.hasImage = false)
        //
        // else{
        // }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async createOrganizationMethod(action) {
      switch (action) {
        case "CREATE":
          var mutationname = createOrganization;
          var mutationresult = "CreateOrganization";
          var inputParams = this.create_organization;
          inputParams.creator_user_id = this.$store.getters.get_current_user_details.user_id;
          inputParams.organization_color_code = this.organization_color_code;
          inputParams.organization_logo_url =
            mutationname == "CreateOrganization" ? this.$store.getters.get_image_url : undefined;
          inputParams.organization_logo_shape = this.organization_logo_shape;
          break;
        case "EDIT":
          console.log("StoreObj.hasImage", this.StoreObj.organization_logo_url);
          mutationname = updateOrganization;
          mutationresult = "UpdateOrganization";
          inputParams = { ...this.create_organization };
          delete inputParams.organization_logo_url;
          inputParams.organization_id = this.StoreObj.organization_id;
          inputParams.organization_logo_url =
            this.hasImage == false ? this.StoreObj.organization_logo_url : this.$store.getters.get_image_url;
          inputParams.organization_color_code = this.organization_color_code;
          inputParams.organization_logo_url = this.$store.getters.get_image_url;
          inputParams.organization_logo_shape = this.organization_logo_shape;
          delete inputParams.contact_person_user_name;
          delete inputParams.contact_person_email_id;
          delete inputParams.contact_person_country_code;
          delete inputParams.contact_person_contact_number;
          break;
      }
      this.loading = true;

      try {
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createOrganizationsDialogEmit((this.Toggle = 2));
          this.loading = false;
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createOrganizationsDialogEmit(Toggle) {
      this.imageUrl = "";
      this.create_organization.organization_color_code = "#000000";
      this.create_organization.minimum_minutes = null;
      this.create_organization.maximum_minutes = null;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
