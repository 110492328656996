import { API } from "aws-amplify";
export const GetAllInitialAlarms = {
  data() {
    return {
      GetAllInitialAlarmsList: [],
    };
  },
  methods: {
    async GetAllInitialAlarmsListMethod(asset) {
      var self = this;
      self.overlay = true;
      const path = "getalarmsfororganizationwithdate";
      const myInit = {
        body: {
          organization_id: asset.organization_id,
          asset_id: asset.asset_id,
          date: new Date().toISOString().substr(0, 10),
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (self.$route.name != "Dashboard") {
            self.overlay = false;
          }
          if (!response.errorType) {
            self.GetAllInitialAlarmsList = response.data.items;
          } else {
            self.GetAllInitialAlarmsList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          self.overlay = false;
          console.warn(error);
        });
    },
  },
};
