<template>
  <div>
    <v-dialog fullscreen persistent v-model="PreviewDialog">
      <v-card>
        <v-toolbar dense color="primary" dark class="elevation-0">
          <v-spacer />
          <v-btn icon small @click="PreviewDialogEmit()"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card class="elevation-0">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" xs="12" md="3" class="px-1">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0">
                      <v-card outlined class="overflow-y-auto elevation-10">
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                          <v-data-table
                            dense
                            :height="`${(OPHeight - 200) / 2}`"
                            :fixed-header="true"
                            class="elevation-0"
                            :headers="operationalHeader"
                            :items-per-page="
                              GetAllDashboardParameterList.filter(
                                (item) =>
                                  item.tag_type == 'AI' &&
                                  (item.show_on_dashboard == undefined || item.show_on_dashboard == true)
                              ).length
                            "
                            :items="
                              sortMethod(
                                GetAllDashboardParameterList.filter(
                                  (item) =>
                                    item.tag_type == 'AI' &&
                                    (item.show_on_dashboard == undefined || item.show_on_dashboard == true)
                                )
                              )
                            "
                            hide-default-footer
                          >
                            <template v-slot:top>
                              <v-card outlined>
                                <v-card-subtitle>
                                  <div class="font-weight-bold primaryColor">Operational Parameters</div>
                                </v-card-subtitle>
                              </v-card>
                            </template>
                            <template v-slot:[`item.tag_description`]="{ item }">
                              <div class="fontSize">{{ item.tag_description }}</div>
                            </template>
                            <template v-slot:[`item.actual_value`]="{ item }">
                              <v-card
                                align="center"
                                class="borderRadius elevation-0 px-1"
                                :color="item.color == undefined ? 'grey lighten-3' : item.color"
                              >
                                <div class="fontSize" :class="item.color == 'green' ? 'white--text' : ''">
                                  {{ item.actual_value != undefined ? item.actual_value.toString() : "-" }}
                                </div>
                              </v-card>
                            </template>
                            <template v-slot:[`item.commissioning_value`]="{ item }">
                              <div class="fontSize">{{ item.commissioning_value }}</div>
                            </template>
                          </v-data-table>
                          <v-data-table
                            v-if="renderData"
                            :height="`${(OPHeight - 230) / 2}`"
                            :fixed-header="true"
                            dense
                            class="elevation-0"
                            :headers="alarmHeader"
                            :items-per-page="10"
                            :items="GetAllInitialAlarmsList"
                            hide-default-footer
                            hide-default-header
                          >
                            <template v-slot:top>
                              <v-card outlined>
                                <v-card-subtitle>
                                  <div class="font-weight-bold primaryColor">Alarms</div>
                                </v-card-subtitle>
                              </v-card>
                            </template>
                            <template v-slot:[`item.tag_description`]="{ item }">
                              <div class="fontSize">{{ item.tag_description }}</div>
                              <div class="fontSizeSmall">
                                {{ item.alarm_time ? new Date(item.alarm_time * 1000).toLocaleString("en-In") : "-" }}
                              </div>
                            </template>
                          </v-data-table>
                          <!-- 
                     -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="6" class="px-1">
                  <v-card outlined v-if="renderData" class="elevation-5">
                    <v-card-actions class="py-1">
                      <div class="font-weight-bold primaryColor">Summary</div>
                      <v-spacer></v-spacer>
                      <div>
                        <span>
                          <span class="font-weight-bold">Asset Name:</span
                          ><span class="green-text"> {{ StoreAllCommonObject.asset_name }}</span>
                        </span>
                        <br />
                        <span class="pt-n4">
                          <span class="font-weight-bold">Asset ID:</span>
                          <span> {{ StoreAllCommonObject.asset_type }}</span>
                        </span>
                      </div>
                    </v-card-actions>
                  </v-card>
                  <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" xs="12" md="6" v-for="plant in plant_types" :key="plant">
                          <v-card outlined class="pa-2">
                            <div class="font-weight-bold text--primary">
                              <u
                                >{{ plant }} (
                                {{ plant == "UF" ? "40M³/Hr" : plant == "RO1" ? "28M³/Hr" : "6.5 M³/Hr" }} )</u
                              >
                            </div>
                            <span class="font-weight-bold text--primary">Step :</span>
                            <v-icon
                              small
                              class="mx-1"
                              :color="
                                stepObject[plant] == undefined
                                  ? 'red'
                                  : stepObject[plant]['step_name'].toLowerCase().includes('idle')
                                  ? 'red'
                                  : stepObject[plant].color.toLowerCase()
                              "
                              >mdi-checkbox-blank-circle</v-icon
                            >
                            <span
                              class="fontSize font-weight-bold"
                              :class="
                                stepObject[plant] == undefined
                                  ? 'red--text'
                                  : stepObject[plant]['step_name'].toLowerCase().includes('idle')
                                  ? 'red--text'
                                  : `${stepObject[plant].color.toLowerCase()}--text`
                              "
                              >{{
                                stepObject[plant] == undefined
                                  ? `IDLE STEP`
                                  : stepObject[plant]["step_name"].toUpperCase()
                              }}
                            </span>
                            <br />
                            <span class="font-weight-bold">Recovery (%) :</span>
                            <span
                              v-if="
                                stepObject[plant] != undefined &&
                                stepObject[plant]['step_name'].toLowerCase().includes('idle') == false
                              "
                              :class="parseInt(recovery_percentage[plant]) > 100 ? 'red--text' : ''"
                            >
                              {{
                                recovery_percentage[plant] && recovery_percentage[plant] != ""
                                  ? parseInt(recovery_percentage[plant]) > 100
                                    ? "ERROR"
                                    : recovery_percentage[plant]
                                  : ""
                              }}
                            </span>
                            <span v-else class="red--text font-weight-bold">IDLE</span>

                            <div class="font-weight-bold">Status</div>
                            <v-simple-table dense v-if="renderData">
                              <template v-slot:default>
                                <tbody>
                                  <tr
                                    :style="`background-color:${!item.bgcolor ? '#F5F5F5' : item.bgcolor}`"
                                    v-for="item in sortMethod(
                                      GetAllDashboardParameterList.filter(
                                        (item) =>
                                          item.tag_type == 'DI' &&
                                          item.status_or_feedback == 'STATUS' &&
                                          item.plant_name == plant &&
                                          (item.show_on_dashboard == undefined || item.show_on_dashboard == true)
                                      )
                                    )"
                                    :key="item.name"
                                  >
                                    <td class="fontSize" style="width: 220px">
                                      {{ item.tag_description }}
                                    </td>
                                    <td>
                                      <v-icon
                                        x-small
                                        :color="
                                          !item.color || item.color == 'RED'
                                            ? 'red'
                                            : item.color == 'GREEN'
                                            ? 'green'
                                            : 'yellow'
                                        "
                                        >mdi-checkbox-blank-circle</v-icon
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <div class="font-weight-bold">Feedback</div>
                            <v-simple-table dense v-if="renderData">
                              <template v-slot:default>
                                <tbody>
                                  <tr
                                    :style="`background-color:${!item.bgcolor ? '#F5F5F5' : item.bgcolor}`"
                                    v-for="item in sortMethod(
                                      GetAllDashboardParameterList.filter(
                                        (item) =>
                                          item.tag_type == 'DI' &&
                                          item.status_or_feedback == 'FEEDBACK' &&
                                          item.plant_name == plant &&
                                          (item.show_on_dashboard == undefined || item.show_on_dashboard == true)
                                      )
                                    )"
                                    :key="item.name"
                                  >
                                    <td class="fontSize" style="width: 220px">
                                      {{ item.tag_description }}
                                    </td>
                                    <td>
                                      <v-icon
                                        x-small
                                        :color="
                                          !item.color || item.color == 'RED'
                                            ? 'red'
                                            : item.color == 'GREEN'
                                            ? 'green'
                                            : 'yellow'
                                        "
                                        >mdi-checkbox-blank-circle</v-icon
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="3" class="px-1">
                  <v-card outlined class="elevation-5">
                    <v-card-subtitle>
                      <div class="font-weight-bold primaryColor">Daily Logs</div>
                    </v-card-subtitle>
                  </v-card>
                  <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-divider></v-divider>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" xs="12" md="12" v-for="plant in plant_types" :key="plant">
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr>
                                  <td class="fontSize" width="120px">{{ plant }} Start / Stop</td>
                                  <td class="fontSize" v-if="dailyLogs[plant] != undefined">
                                    <v-row no-gutters class="borderLeft text-right">
                                      <v-col cols="12" xs="12" sm="12" md="6">Start</v-col>
                                      <v-col cols="12" xs="12" sm="12" md="6">Stop</v-col>
                                    </v-row>
                                    <v-row
                                      no-gutters
                                      v-for="(time, idx) in dailyLogs[plant].slice().reverse()"
                                      :key="idx"
                                      v-show="idx < 4"
                                      class="borderLeft text-right fontSize"
                                    >
                                      <v-col cols="12" xs="12" sm="12" md="6">{{
                                        time.start != undefined ? new Date(time.start * 1000).toLocaleTimeString() : "-"
                                      }}</v-col>
                                      <v-col cols="12" xs="12" sm="12" md="6">{{
                                        time.stop != undefined ? new Date(time.stop * 1000).toLocaleTimeString() : "-"
                                      }}</v-col>
                                    </v-row>
                                  </td>
                                  <td class="fontSize text-right" v-else>-</td>
                                </tr>
                                <tr>
                                  <td class="fontSize">{{ plant }} total hours of Operation :</td>
                                  <td
                                    class="fontSize text-right"
                                    v-if="dailyLogs[`${plant.toLowerCase()}_total_hours_of_operation`] != undefined"
                                  >
                                    {{
                                      convertTimeToHRMinSec(
                                        dailyLogs[`${plant.toLowerCase()}_total_hours_of_operation`],
                                        dailyLogs[plant],
                                        plant
                                      )
                                    }}
                                  </td>
                                  <td class="fontSize text-right" v-else>-</td>
                                </tr>
                                <tr>
                                  <td class="fontSize">{{ plant }} Water Produced (M³):</td>
                                  <td
                                    class="fontSize text-right"
                                    v-if="dailyLogs[`${plant.toLowerCase()}_water_produced`] != undefined"
                                  >
                                    {{
                                      parseFloat(dailyLogs[`${plant.toLowerCase()}_water_produced`])
                                        ? dailyLogs[`${plant.toLowerCase()}_water_produced`]
                                        : "-"
                                    }}
                                  </td>
                                  <td class="fontSize text-right" v-else>-</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td class="fontSize">DMF Manual Backwash (M³/Day):</td>
                              <td
                                class="fontSize text-right"
                                v-if="dailyLogs[`dmf_manual_backwash_value`] != undefined"
                              >
                                {{ dailyLogs[`dmf_manual_backwash_value`] }}
                              </td>
                              <td class="fontSize text-right" v-else>-</td>
                            </tr>
                            <tr>
                              <td class="fontSize">UF Plant Inlet (M³/Day):</td>
                              <td class="fontSize text-right" v-if="dailyLogs[`uf_plant_inlet`] != undefined">
                                {{ dailyLogs[`uf_plant_inlet`] }}
                              </td>
                              <td class="fontSize text-right" v-else>-</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GetAllDashboardParameter } from "@/mixins/GetPreviewMethod.js";
import { GetAllInitialAlarms } from "@/mixins/GetAllInitialAlarms.js";
export default {
  props: {
    PreviewDialog: Boolean,
    StoreAllCommonObject: Object,
  },
  mixins: [GetAllDashboardParameter, GetAllInitialAlarms],
  data: () => ({
    Toggle: 0,
    GetAllInitialAlarmsList: [],
    operationalHeader: [
      { text: "Parameter", value: "tag_description" },
      { text: "Actual", value: "actual_value" },
      { text: "Comm.", value: "commissioning_value" },
    ],
    alarmHeader: [{ text: "Name", value: "tag_description" }],
    Height: 0,
    plant_types: [],
    GetAllDashboardParameterList: [],
    summaryHeaders: [
      { text: "", value: "tag_description" },
      { text: "", value: "alarm_color" },
    ],
    ConnectionDashboard: null,
    stepObject: {},
    dailyLogs: {},
    setIntervalString: null,
    connected: false,
    screenHeight: 0,
    OPHeight: 0,
    heart_beat_color: "",
    recovery_percentage: {},
    count: 0,
    reloadcount: 0,
    renderOPData: true,
    renderData: true,
    startstop: null,
  }),
  watch: {
    PreviewDialog(val) {
      if (val == true) {
        console.log("TEst", this.StoreAllCommonObject);
        this.GetAllDashboardParameterMethod(
          this.StoreAllCommonObject.organization_id,
          this.StoreAllCommonObject.asset_id
        );
        this.GetAllInitialAlarmsListMethod(this.StoreAllCommonObject);
      }
    },
    GetAllDashboardParameterList(val) {
      this.plant_types = [];
      if (val && val.length != 0) {
        this.heart_beat_color = val.filter((item) => item.type == "HEARTBEAT")[0].color;
        this.$emit("heartBeatColor", this.heart_beat_color);
        let plant_type = [...new Set(val.filter((item) => item.tag_type == "DI").map((plant) => plant.plant_name))]
          .sort()
          .reverse();
        let diff_plant_types = [];
        plant_type.map((plant) => {
          if (!isNaN(Number(plant.slice(-1)))) {
            if (diff_plant_types.indexOf(plant.slice(0, plant.length - 1)) == -1) {
              diff_plant_types.push(plant.slice(0, plant.length - 1));
            }
          } else {
            if (diff_plant_types.indexOf(plant) == -1) {
              diff_plant_types.push(plant);
            }
          }
        });
        this.plant_types = diff_plant_types
          .map((plant) => plant_type.filter((plant_name) => plant_name.includes(plant)).sort())
          .flat(Infinity);
        this.recovery_percentage = {};
        for (let i = 0; i < this.plant_types.length; i++) {
          this.recovery_percentage[this.plant_types[i]] = "";
          for (let j = 0; j < val.filter((item) => item.type == "RECOVERY").length; j++) {
            if (this.plant_types[i] == val.filter((item) => item.type == "RECOVERY")[j].plant_name) {
              this.recovery_percentage[this.plant_types[i]] = val.filter((item) => item.type == "RECOVERY")[
                j
              ].actual_value;
            }
          }
        }

        this.stepObject = {};
        for (let i = 0; i < val.filter((item) => item.type == "STEP").length; i++) {
          this.stepObject[val.filter((item) => item.type == "STEP")[i].plant_name] = val.filter(
            (item) => item.type == "STEP"
          )[i];
        }
        let dailyLogsObject = val.filter((item) => item.type == "DAILY_LOGS")[0];
        if (dailyLogsObject["dmf_manual_backwash_value"] != undefined) {
          this.dailyLogs["dmf_manual_backwash_value"] = dailyLogsObject["dmf_manual_backwash_value"];
        }
        if (dailyLogsObject["uf_plant_inlet"] != undefined) {
          this.dailyLogs["uf_plant_inlet"] = dailyLogsObject["uf_plant_inlet"];
        }
        for (let i = 0; i < this.plant_types.length; i++) {
          if (dailyLogsObject[this.plant_types[i]] != undefined) {
            this.dailyLogs[this.plant_types[i]] = dailyLogsObject[this.plant_types[i]];
            this.dailyLogs[`${this.plant_types[i].toLowerCase()}_water_produced`] =
              dailyLogsObject[`${this.plant_types[i].toLowerCase()}_water_produced`] != undefined
                ? dailyLogsObject[`${this.plant_types[i].toLowerCase()}_water_produced`]
                : 0;
            this.dailyLogs[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] =
              dailyLogsObject[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] != undefined
                ? dailyLogsObject[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`]
                : 0;
          }
        }
      }
    },
  },
  created() {
    if (this.ConnectionDashboard != null) {
      this.ConnectionDashboard.close();
    }
    this.WebSocketConection();
  },
  beforeDestroy() {
    this.ConnectionDashboard.close();
    clearInterval(this.startstop);
  },
  mounted() {
    this.Height = window.innerHeight - 155;
    this.OPHeight = window.innerHeight;
    this.screenHeight = screen.height;
  },
  methods: {
    PreviewDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
    setReconnectMethod() {
      var self = this;
      self.reconnect = setInterval(() => {
        self.ConnectionDashboard.close();
        self.WebSocketConection();
      }, 10000);
    },
    sortMethod(array) {
      return array.sort((a, b) => a.sort_count - b.sort_count);
    },
    setIntervalMethod() {
      var self = this;
      self.startstop = setInterval(function () {
        if (self.dailyLogs && Object.keys(self.dailyLogs).length != 0) {
          for (let i = 0; i < self.plant_types.length; i++) {
            for (let key in self.dailyLogs) {
              if (self.plant_types[i] == key) {
                if (self.dailyLogs[self.plant_types[i]].length != 0) {
                  if (
                    self.dailyLogs[self.plant_types[i]][self.dailyLogs[self.plant_types[i]].length - 1].stop ==
                    undefined
                  ) {
                    self.dailyLogs[`${key.toLowerCase()}_total_hours_of_operation`] += 1;
                    self.$forceUpdate();
                  }
                }
              }
            }
          }
        }
      }, 1000);
    },
    convertTimeToHRMinSec(time) {
      let value = time * 1000;
      this.hour = Math.floor(value / 1000 / 60 / 60);
      value -= this.hour * 1000 * 60 * 60;
      this.minute = Math.floor(value / 1000 / 60);
      value -= this.minute * 1000 * 60;
      this.seconds = Math.floor(value / 1000);
      return this.addZero(this.hour) + ":" + this.addZero(this.minute) + ":" + this.addZero(this.seconds);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getValue(item) {
      return item.actual_value.toFixed(2);
    },
    WebSocketConection() {
      var self = this;
      self.ConnectionDashboard = new WebSocket(
        `wss://lnuzjftr1a.execute-api.ap-south-1.amazonaws.com/chainhome?organization_id=${self.$store.getters.get_current_user_details.organization_id}&user_id=${self.$store.getters.get_current_user_details.user_id}`
      );
      self.ConnectionDashboard.onopen = function () {
        console.log("Connected!!!");
        self.connected = true;
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onclose = function () {
        console.log("Disconnected On Close!!!");
        self.connected = false;
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onerror = function () {
        self.connected = false;
        console.log("Disconnected On Error!!!");
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onmessage = async function (event) {
        if (self.GetAllDashboardParameterList.length != 0) {
          console.log("Check The Data", JSON.parse(event.data));
          await self.formatData(JSON.parse(event.data));
        }
      };
    },
    changeBGColor(data) {
      var self = this;
      setTimeout(() => {
        data.bgcolor = "#F5F5F5";
        self.$forceUpdate();
      }, 1000);
    },
    changeColor(data) {
      var self = this;
      setTimeout(() => {
        data.color = "grey lighten-3";
        self.$forceUpdate();
      }, 1000);
    },
    async formatData(data) {
      switch (data.type) {
        case "HEARTBEAT":
          this.$emit("heartBeatColor", data.color);
          break;
        case "OPERATIONAL_PARAMETER":
          for (let i = 0; i < this.GetAllDashboardParameterList.length; i++) {
            if (
              this.GetAllDashboardParameterList[i].tag_id == data.tag_id &&
              this.GetAllDashboardParameterList[i].actual_value != data.actual_value &&
              typeof data.actual_value == "string"
            ) {
              this.GetAllDashboardParameterList[i].actual_value = data.actual_value;
              if (data.color_settings) {
                let color_found = false;
                let changed_color = "";
                for (let i = 0; i < data.color_settings.length; i++) {
                  if (
                    Number(data.actual_value) >= data.color_settings[i].start &&
                    Number(data.actual_value) <= data.color_settings[i].end
                  ) {
                    color_found = true;
                    changed_color = data.color_settings[i].color.toLowerCase();
                  }
                }
                if (color_found == true) {
                  this.GetAllDashboardParameterList[i].color = changed_color;
                } else {
                  this.GetAllDashboardParameterList[i].color = "orange";
                }
                this.changeColor(this.GetAllDashboardParameterList[i]);
              } else {
                this.GetAllDashboardParameterList[i].color = "orange";
                this.changeColor(this.GetAllDashboardParameterList[i]);
              }
              this.renderOPData = false;
              this.$nextTick(() => {
                this.renderOPData = true;
              });
            }
          }

          break;
        case "SUMMARY_PARAMETER":
          for (let i = 0; i < this.GetAllDashboardParameterList.length; i++) {
            if (
              this.GetAllDashboardParameterList[i].tag_id == data.tag_id &&
              this.GetAllDashboardParameterList[i].actual_value != data.actual_value &&
              typeof data.actual_value == "string"
            ) {
              this.GetAllDashboardParameterList[i].color = data.color;
              this.GetAllDashboardParameterList[i].bgcolor = data.color == "GREEN" ? "#e5ffe5" : "#ffe5e5";
              this.changeBGColor(this.GetAllDashboardParameterList[i]);
              this.renderData = false;
              this.$nextTick(() => {
                this.renderData = true;
              });
            }
          }
          if (data.step_name) {
            this.renderData = false;
            this.$nextTick(() => {
              this.stepObject[data.plant_name] = data;
              this.renderData = true;
            });
          }
          break;
        case "ALARM":
          this.GetAllInitialAlarmsList.unshift(data);
          break;
        case "DAILY_LOGS":
          if (data.type == "DAILY_LOGS") {
            if (data.parameter == "dmf_manual_backwash_value") {
              this.dailyLogs["dmf_manual_backwash_value"] = data["value"];
            }
            if (data.parameter == "uf_plant_inlet") {
              this.dailyLogs["uf_plant_inlet"] = data["value"];
            }
            if (data.data) {
              for (let i = 0; i < this.plant_types.length; i++) {
                if (data.data[this.plant_types[i]] != undefined) {
                  this.dailyLogs[this.plant_types[i]] = data.data[[this.plant_types[i]]];
                }
              }
              for (let key in data) {
                if (key != "data") {
                  this.dailyLogs[key] = data[key];
                }
              }
            } else {
              for (let i = 0; i < this.plant_types.length; i++) {
                if (data.parameter == `${this.plant_types[i].toLowerCase()}_recovery_percentage`) {
                  this.recovery_percentage[this.plant_types[i]] = "";
                  this.recovery_percentage[this.plant_types[i]] = data.value;
                }
              }
              for (let key in data) {
                for (let i = 0; i < this.plant_types.length; i++) {
                  if (data[key] == `${this.plant_types[i].toLowerCase()}_water_produced`) {
                    this.dailyLogs[`${this.plant_types[i].toLowerCase()}_water_produced`] = data.value;
                  }
                  if (data[key] == `${this.plant_types[i].toLowerCase()}_total_hours_of_operation`) {
                    this.dailyLogs[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] = data.value;
                  }
                }
              }
            }
            this.renderData = false;
            this.$nextTick(() => {
              this.renderData = true;
            });
          }
          break;
      }
    },
  },
};
</script>

<style></style>
